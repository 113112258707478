import { ActionIcon, Button, Flex, Select, Textarea, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useStore } from 'effector-react'
import { $currentEvent } from 'entities'
import { useNavigate } from 'react-router'
// eslint-disable-next-line import/no-internal-modules
import { EditIcon } from 'shared/asset'
import { formatDateEvent } from 'shared/lib/format-date'
import { CustomDateTimePicker } from 'shared/ui'
import { $edit, isEdit } from '../model'
import {
  categoryEventName,
  formatEventName,
  getCategoryEvent,
  getformatEvent,
  isEditEvents,
} from '../utils'

export const EventsTableInfo = () => {
  const currentEvent = useStore($currentEvent)
  const editEvent = useStore($edit)
  const navigate = useNavigate()

  const [dateString, timeString] = currentEvent.date[0].dateFrom.split(' ')
  const [day, month, year] = dateString.split('.')
  const [hours, minutes, seconds] = timeString.split(':')
  const dateTime = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds)

  const currentEventLocation = currentEvent.location
    ? currentEvent.location
    : {
        addressName: 'не указано',
        description: 'не указано',
      }

  const currentEventProperties = currentEvent.properties
    ? currentEvent.properties
    : {
        other: 'не указано',
      }

  const currentEventCategoryName = currentEvent.categoryName
    ? currentEvent.categoryName
    : 'не указанно'

  const currentEventTypeName = currentEvent.typeName
    ? currentEvent.typeName
    : 'не указанно'

  const currentEventLimits = currentEvent.limits
    ? currentEvent.limits
    : {
        students: 0,
      }

  const form = useForm({
    initialValues: {
      typeEvent: currentEventCategoryName,
      formatEvent: currentEventTypeName,
      addressEvent: currentEventLocation.addressName,
      date: dateTime,
      howToGet: currentEventLocation.description,
      eventDescription: currentEventProperties.other,
    },
    validateInputOnBlur: true,
    validate: {
      typeEvent: (value) => (/^.+$/.test(value) ? false : 'поле не должно быть пустым'),
      formatEvent: (value) => (/^.+$/.test(value) ? false : 'поле не должно быть пустым'),
      addressEvent: (value) =>
        /^.+$/.test(value) ? false : 'поле не должно быть пустым',
      // date: (value) => (/^.+$/.test(value) ? false : 'поле не должно быть пустым'),
      howToGet: (value) => (/^.+$/.test(value) ? false : 'поле не должно быть пустым'),
      eventDescription: (value) =>
        /^.+$/.test(value) ? false : 'поле не должно быть пустым',
    },
  })

  const handleSubmit = (values: any) => {
    const event = {
      ...values,
      eventID: currentEvent.eventID,
      numberUsers: currentEventLimits.students,
      name: currentEvent.eventName,
      formatEvent: getformatEvent(values.formatEvent),
      typeEvent: getCategoryEvent(values.typeEvent),
      date: formatDateEvent(values.date),
    }
    isEdit(false)
    isEditEvents(navigate, event)
  }
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        handleSubmit(values)
      })}
    >
      <Flex direction='column' w='100%'>
        <Flex w='100%'>
          <Flex p={12} w={172} c='#8F8F8F'>
            Дата проведения
          </Flex>
          {editEvent ? (
            // <DateTimePicker
            //   withAsterisk
            //   key={form.key('date')}
            //   {...form.getInputProps('date')}
            //   radius={8}
            //   placeholder='Дата и время'
            // />
            <CustomDateTimePicker
              key={form.key('date')}
              {...form.getInputProps('date')}
            />
          ) : (
            <Flex p={12}>
              {currentEvent.date[0].dateFrom}
              <ActionIcon
                color='white'
                onClick={() => {
                  isEdit(true)
                }}
              >
                <EditIcon />
              </ActionIcon>
            </Flex>
          )}
        </Flex>
        <Flex w='100%'>
          <Flex p={12} w={172} c='#8F8F8F'>
            Тип
          </Flex>

          {editEvent ? (
            <Select
              withAsterisk
              key={form.key('typeEvent')}
              {...form.getInputProps('typeEvent')}
              w={280}
              radius={8}
              placeholder='Тип события'
              data={categoryEventName}
            />
          ) : (
            <Flex p={12}>{currentEvent.categoryName}</Flex>
          )}
        </Flex>
        <Flex w='100%'>
          <Flex p={12} w={172} c='#8F8F8F'>
            Адрес проведения
          </Flex>
          {editEvent ? (
            <TextInput
              withAsterisk
              key={form.key('addressEvent')}
              {...form.getInputProps('addressEvent')}
              placeholder='Адрес проведения'
              radius={8}
              w={280}
            />
          ) : (
            <Flex p={12}>{currentEventLocation.addressName}</Flex>
          )}
        </Flex>
        <Flex w='100%'>
          <Flex p={12} w={172} c='#8F8F8F'>
            Формат
          </Flex>
          {editEvent ? (
            <Select
              withAsterisk
              key={form.key('formatEvent')}
              {...form.getInputProps('formatEvent')}
              w={280}
              radius={8}
              placeholder='Формат'
              data={formatEventName}
            />
          ) : (
            <Flex p={12}>{currentEvent.typeName}</Flex>
          )}
        </Flex>
        <Flex w='100%'>
          <Flex p={12} w={220} c='#8F8F8F'>
            Как добраться
          </Flex>
          {editEvent ? (
            <Textarea
              withAsterisk
              key={form.key('howToGet')}
              {...form.getInputProps('howToGet')}
              w='100%'
              radius={8}
              placeholder='Как добратся'
            />
          ) : (
            <Flex w='100%' p={12}>
              {currentEventLocation.description
                ? currentEventLocation.description
                : 'не указанно'}
            </Flex>
          )}
        </Flex>
        <Flex w='100%'>
          <Flex p={12} w={220} c='#8F8F8F'>
            Описание
          </Flex>
          {editEvent ? (
            <Textarea
              withAsterisk
              key={form.key('eventDescription')}
              {...form.getInputProps('eventDescription')}
              w='100%'
              radius={8}
              placeholder='Описание события'
            />
          ) : (
            <Flex w='100%' p={12}>
              {currentEventProperties.other
                ? currentEventProperties.other
                : 'не указанно'}
            </Flex>
          )}
        </Flex>
        {editEvent ? (
          <Button
            mt={20}
            disabled={!form.isValid()}
            type='submit'
            color='black'
            radius={8}
            w={300}
          >
            Отправить
          </Button>
        ) : (
          <Flex></Flex>
        )}
      </Flex>
    </form>
  )
}
