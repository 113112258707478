import { isLoader, setAlertState } from 'entities'
import ky from 'shared/api/ky'
import { getUsersKy } from 'shared/api/users'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
import { User } from './types'

export const deleteUser = async (navigate: any, userID: string) => {
  isLoader()
  const res = await ky.post('users/delete_account', {
    json: {
      userID: userID,
    },
  })

  if (res.status === 400) {
    setAlertState('delete_account 400')
    isLoader()
    return
  }

  if (res.status === 401) {
    removeItemAndExit()
    navigate('/login')
    isLoader()
    return
  }

  if (res.status === 403) {
    setAlertState('недостаточно прав')
    isLoader()
    return
  }

  if (res.status === 412) {
    removeItemAndExit()
    navigate('/login')
    isLoader()
    return
  }

  if (res.status === 500) {
    setAlertState('delete_account 500')
    isLoader()
    return
  }

  if (res.status === 200) {
    isLoader()
    getUsersKy(navigate, 1, '')
    return
  }
}

export const usersInitial = [
  {
    userID: '8fdb6c25-e06b-4c11-85b4-ca77362bda21',
    firstName: 'Виталий',
    lastName: 'Шестаков',
    userRoles: [
      {
        info: {
          salary: 0,
          mentor_level: null,
        },
        permission: 4,
        role: 'Студент',
      },
    ],
    contactInfo: [
      {
        contactName: 'phone',
        contactType: 3,
        isVerified: true,
        contact: '72312321312',
      },
    ],
  },
]

export const mockUser: User = {
  aboutMe: '',
  background: {
    competence: '',
    employment: '',
  },
  bans: null,
  birthday: '01.01.0000',
  bitrixID: 0,
  contactInfo: [
    {
      contact: '',
      contactName: '',
      contactType: 1,
      isVerified: true,
    },
  ],
  createdAt: '',
  enableNotification: true,
  firstName: '',
  isBanned: false,
  lastName: '',
  location: {
    addressName: 'не указанно',
    cityName: '',
    countryName: '',
    districtName: '',
  },
  middleName: '',
  oldUserID: 0,
  photo: {
    coord: {
      height: 0,
      width: 0,
      x: 0,
      y: 0,
    },
    photoBase64: '',
  },
  photoURL: '',
  updatedAt: '',
  useTOTP: true,
  userEventID: 0,
  userEventName: '',
  userID: '',
  userRoles: [
    {
      info: {
        mentor_level: 0,
        salary: 0,
      },
      permission: 0,
      role: '',
    },
  ],
}

export const returnCorrectUser = (state: User, user: User): User => {
  return {
    aboutMe: nullСheck(user.aboutMe, state.aboutMe),
    background: user.background
      ? {
          competence: nullСheck(user.background.competence, state.background.competence),
          employment: nullСheck(user.background.employment, state.background.employment),
        }
      : state.background,
    bans: null,
    birthday: nullСheck(user.birthday, state.birthday),
    bitrixID: nullСheck(user.bitrixID, state.bitrixID),
    contactInfo: user.contactInfo ? user.contactInfo : state.contactInfo,
    createdAt: nullСheck(user.createdAt, state.createdAt),
    enableNotification: user.enableNotification ? true : false,
    firstName: nullСheck(user.firstName, state.firstName),
    isBanned: false,
    lastName: nullСheck(user.lastName, state.lastName),
    location: user.location
      ? {
          addressName: nullСheck(user.location.addressName, state.location.addressName),
          cityName: nullСheck(user.location.cityName, state.location.cityName),
          countryName: nullСheck(user.location.countryName, state.location.countryName),
          districtName: nullСheck(
            user.location.districtName,
            state.location.districtName
          ),
        }
      : state.location,
    middleName: nullСheck(user.middleName, state.middleName),
    oldUserID: nullСheck(user.oldUserID, state.oldUserID),
    photo: user.photo
      ? {
          coord: user.photo.coord
            ? {
                height: nullСheck(user.photo.coord.height, state.photo.coord.height),
                width: nullСheck(user.photo.coord.width, state.photo.coord.width),
                x: nullСheck(user.photo.coord.x, state.photo.coord.x),
                y: nullСheck(user.photo.coord.y, state.photo.coord.y),
              }
            : state.photo.coord,
          photoBase64: nullСheck(user.photo.photoBase64, state.photo.photoBase64),
        }
      : state.photo,
    photoURL: nullСheck(user.photoURL, state.photoURL),
    updatedAt: nullСheck(user.updatedAt, state.updatedAt),
    useTOTP: user.useTOTP ? true : false,
    userEventID: nullСheck(user.userEventID, state.userEventID),
    userEventName: nullСheck(user.userEventName, state.userEventName),
    userID: nullСheck(user.userID, state.userID),
    userRoles: nullСheck(user.userRoles, state.userRoles),
  }
}

const nullСheck = (value: any, state: any) => {
  return value ? value : state
}
