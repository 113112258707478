import { Flex } from '@mantine/core'
import { LoginPageContent, Sidebar } from 'shared/ui'

const LoginPage = () => {
  return (
    <div>
      <Flex w='100dvw' h='100dvh'>
        <Sidebar bg='white' pl={60} pr={0} />
        <LoginPageContent />
      </Flex>
    </div>
  )
}

export default LoginPage
