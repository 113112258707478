import { Flex } from '@mantine/core'
// eslint-disable-next-line boundaries/element-types
import MultiStepForm from 'widgets/multi-step-form'
// eslint-disable-next-line import/no-internal-modules
import { Subtract } from 'shared/asset'

// const LoginModule = lazy(() => import('terra-login'))

export const LoginPageContent = () => {
  return (
    <Flex w='100%' h='100%'>
      <Flex align='center' w='50%' h='100%'>
        {/* <LoginModule /> */}
        <MultiStepForm />
      </Flex>
      <Flex w='50%' h='100%' bg='#F9F9F9' align='center' justify='center'>
        <Subtract />
      </Flex>
    </Flex>
  )
}
