import KyStandart from 'ky'
import { getGlobalArg } from 'shared/lib/get-global-args'

const API_URL = getGlobalArg('BASE_API_URL')

const prepareRequestsWithAuth = (request: Request) => {
  const authToken = localStorage.getItem('apiKey')

  request.headers.set('Authorization', `${authToken}`)
}

export default KyStandart.create({
  prefixUrl: API_URL,
  hooks: {
    beforeRequest: [prepareRequestsWithAuth],
  },
})
