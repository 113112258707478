/* eslint-disable boundaries/element-types */
import { createStore } from 'effector'

import { setAllCategory, setCurrentUser } from './events'
// eslint-disable-next-line boundaries/element-types
import { MentorsCategoty } from 'shared/lib/shared-types'
import { mockUser, returnCorrectUser, User } from 'widgets/users-table'

export const $mentorsCategory = createStore<MentorsCategoty[]>([]).on(
  setAllCategory,
  (state, categorys) => categorys
)

export const $currentUser = createStore<User>(mockUser).on(
  setCurrentUser,
  (state, user) => returnCorrectUser(mockUser, user)
)
