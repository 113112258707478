import ky from 'shared/api/ky'
import { setCategoryEvent } from './model'

export const mockcCategoryEvent = [
  {
    categoryID: 1,
    categoryName: 'Обычные события',
    categoryDescription: 'фывфыв',
  },
]

export const requestCategoryEvent = async () => {
  const res = await ky.get('event/fetch_event_categories', {})

  if (res.status === 200) {
    const data: any = await res.json()
    setCategoryEvent(data)
    return
  }
}
