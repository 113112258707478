import { createStore } from 'effector'
import { User } from '../types'
import { mockUser, returnCorrectUser } from '../utils'
import { setUsers } from './events'

export const $users = createStore<User[]>([mockUser]).on(setUsers, (state, users) => {
  return users.map((user: User) => {
    return returnCorrectUser(mockUser, user)
  })
})
