import { Flex } from '@mantine/core'
import { TableRolesProps } from '../types'

export const TableRoles = ({ roles }: TableRolesProps) => {
  const rolesLength = roles.length - 1
  return (
    <Flex gap={5}>
      <Flex
        align='center'
        justify='center'
        p='4px 8px'
        bg='#EEF6FF'
        c='#1681FF'
        style={{
          borderRadius: '36px',
        }}
      >
        {roles[0].role}
      </Flex>
      {rolesLength !== 0 ? (
        <Flex
          align='center'
          justify='center'
          p='4px 8px'
          bg='#EEF6FF'
          c='#1681FF'
          style={{
            borderRadius: '36px',
          }}
        >
          {`+${rolesLength}`}
        </Flex>
      ) : null}
    </Flex>
  )
}
