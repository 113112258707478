import { createStore } from 'effector'
import { Event } from '../types'
import { mockEvent, returnCorrectEvent } from '../utils'
import { setEvents } from './events'

export const $events = createStore<Event[]>([mockEvent]).on(
  setEvents,
  (state, events) => {
    return events.map((event: Event) => {
      return returnCorrectEvent(mockEvent, event)
    })
  }
)
