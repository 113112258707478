import { isLoaderEvents, setAlertState, setAllCategory } from 'entities'
// import { getGlobalArg } from 'shared/lib/get-global-args'
import ky from './ky'

export const getAllCategorysKy = async (navigate: any) => {
  const res = await ky.get('mentor/fetch_mentor_category', {})
  if (res.status === 400) {
    setAlertState('fetch_mentor_category 400')
    isLoaderEvents()
    return
  }

  if (res.status === 401) {
    navigate('/login')
    isLoaderEvents()
    return
  }

  if (res.status === 412) {
    navigate('/login')
    isLoaderEvents()
    return
  }

  if (res.status === 500) {
    setAlertState('fetch_mentor_category 500')
    isLoaderEvents()
    return
  }

  if (res.status === 200) {
    const data: any = await res.json()
    setAllCategory(data)
    return
  }
}
