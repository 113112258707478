export function debounce<T extends (...args: any) => any>(
  this: ThisParameterType<T>,
  func: T,
  delay: number
): T {
  // eslint-disable-next-line functional/no-let
  let timeoutId: ReturnType<typeof setTimeout> | null = null

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    // eslint-disable-next-line functional/no-conditional-statements
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      // eslint-disable-next-line no-invalid-this
      func.apply(this, args)
    }, delay)
  } as T
}
