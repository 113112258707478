// eslint-disable-next-line import/no-internal-modules
import { EventsBlue, UsersBlue } from 'shared/asset'

export const dataSwitchingPages = [
  {
    id: 1,
    title: 'События',
    img: <EventsBlue />,
    link: '/events',
  },
  {
    id: 2,
    title: 'Пользователи',
    img: <UsersBlue />,
    link: '/users',
  },
]
