import { ActionIcon, CopyButton, Flex, Tooltip } from '@mantine/core'
// eslint-disable-next-line import/no-internal-modules
import { Copy } from 'shared/asset'
import { formatString } from 'shared/lib/format-string'
import { TableIdProps } from '../types'

export const TableId = ({ id }: TableIdProps) => {
  return (
    <Flex align='center'>
      {formatString(id)}
      <CopyButton value={id} timeout={2000}>
        {({ copied, copy }) => (
          <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position='right'>
            <ActionIcon color={copied ? 'teal' : 'gray'} variant='subtle' onClick={copy}>
              {copied ? <Copy /> : <Copy />}
            </ActionIcon>
          </Tooltip>
        )}
      </CopyButton>
    </Flex>
  )
}
