import { Flex, Title } from '@mantine/core'
import { useStore } from 'effector-react'
import { $roles, setCurrentRole } from 'entities/create-invite-token'
import { Role } from 'shared/lib/shared-types'
import { nextStepInvite } from '../model'
import { getCanCreateInviteUsers } from '../utils'

export const ChooseRole = () => {
  const roles = useStore($roles)
  const allRoles = getCanCreateInviteUsers(roles)
  return (
    <Flex mb={20} p='0px 60px' gap={40} direction='column' w='100%' align='center'>
      <Title>Выберите роль пользователя</Title>
      <Flex direction='column' w='100%' gap={20}>
        {allRoles.map((role: Role) => {
          return <RoleItem key={role.id} role={role} />
        })}
      </Flex>
    </Flex>
  )
}

const RoleItem = ({ role }: any) => {
  return (
    <Flex
      onClick={() => {
        setCurrentRole(role)
        nextStepInvite('InviteGenerationAdmin')
      }}
      align='center'
      justify='center'
      w='100%'
      h={40}
      style={{
        borderRadius: '12px',
        border: '1px solid silver',
        cursor: 'pointer',
      }}
    >
      {role.name}
    </Flex>
  )
}
