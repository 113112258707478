import { Button, Checkbox, Flex, Loader, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useStore } from 'effector-react'
import {
  $loaderStore,
  isLoader,
  newEmail,
  newEmailHash,
  newPhone,
  newPhoneHash,
  setAlertState,
} from 'entities'
import ky from 'shared/api/ky'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
import { nextStep } from '../model'
import { FormPhoneAndEmailProps } from '../types'

export const FormPhoneAndEmail = ({ title }: FormPhoneAndEmailProps) => {
  const loaderStore = useStore($loaderStore)
  const form = useForm({
    initialValues: {
      phoneAndEmail: '',
    },
    validateInputOnBlur: true,
    validate: {
      phoneAndEmail: (value) =>
        /^\+?(\d{1,3})?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d$/.test(value) ||
        /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(value)
          ? false
          : 'Неправильный телефон или email',
    },
  })

  const handleSubmit = async (values: string) => {
    const res = await ky.post('auth/sign_in', {
      json: {
        contact: values,
        useVoice: true,
      },
    })

    if (res.status === 400) {
      setAlertState('sign_in 400')
      isLoader()
      return
    }

    if (res.status === 401) {
      removeItemAndExit()
      setAlertState('sign_in 401')
      isLoader()
      return
    }

    if (res.status === 412) {
      removeItemAndExit()
      setAlertState('sign_in 412')
      isLoader()
      return
    }

    if (res.status === 500) {
      isLoader()
      form.setErrors({ phoneAndEmail: 'Пользователь не найден' })
      return
    }

    if (res.status === 200) {
      isLoader()
      const data: any = await res.json()
      const emailBool = data.contactInfoDecoded[0].name === 'email'
      newPhone(data.contactInfoDecoded[emailBool ? 1 : 0].contact)
      newPhoneHash(data.contactInfoDecoded[emailBool ? 1 : 0].contactHash)
      newEmail(data.contactInfoDecoded[emailBool ? 0 : 1].contact)
      newEmailHash(data.contactInfoDecoded[emailBool ? 0 : 1].contactHash)
      nextStep(
        values.includes('@')
          ? 'FormСonfirmationСodeMessage'
          : 'FormСonfirmationСodePhoneVoice'
      )
      return
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        isLoader()
        handleSubmit(values.phoneAndEmail)
      })}
    >
      <Flex w='80%' gap={20} direction='column'>
        <Title fz={42} fw={600}>
          {title}
        </Title>
        <Text c='#8F8F8F' fz={18} fw={400}>
          Введите телефон или почту, которые привязаны к аккаунту
        </Text>
        <TextInput
          withAsterisk
          key={form.key('phoneAndEmail')}
          {...form.getInputProps('phoneAndEmail')}
          size='lg'
          w='100%'
          placeholder='Телефон или почта'
        />
        <Checkbox
          display={title === 'Вход' ? 'block' : 'none'}
          defaultChecked
          label='Сохранить вход'
        />
        <Button
          disabled={!form.isValid()}
          type='submit'
          size='lg'
          radius={12}
          variant='filled'
          color='black'
        >
          {loaderStore ? <Loader color='white' /> : 'Продолжить'}
        </Button>
      </Flex>
    </form>
  )
}
