/* eslint-disable functional/immutable-data */
import {
  ActionIcon,
  Avatar,
  Button,
  FileInput,
  Flex,
  ScrollArea,
  Select,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useStore, useUnit } from 'effector-react'
import { $userStore } from 'entities'
import { $currentUser, $mentorsCategory } from 'entities/drawer-user-info'
import { useRef } from 'react'
import { useNavigate } from 'react-router'
// eslint-disable-next-line import/no-internal-modules
import { EditIcon } from 'shared/asset'
import { formatDate } from 'shared/lib/format-date'
import { getContactByType } from 'shared/lib/get-contact-by-type'
import { isLinkPhoto } from 'shared/lib/is-link-photo'
import { returnCategoryId } from 'shared/lib/return-category-id'
import { CustomDatePicker } from 'shared/ui'
import { $editUser, isEditUser } from '../model'
import {
  convertToBase64,
  nameTableUserInfo,
  returnCategory,
  updateUsersKy,
} from '../utils'

export const BodyDriwer = () => {
  const editUser = useStore($editUser)
  const currentUser = useUnit($currentUser)
  const mentorsCategory = useStore($mentorsCategory)
  const navigate = useNavigate()
  const formattedDate = new Date(currentUser.createdAt).toLocaleDateString('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })

  const mentorsCategoryName = mentorsCategory.map((category) => category.categoryName)

  const form = useForm({
    initialValues: {
      // eslint-disable-next-line functional/immutable-data
      birthday: new Date(currentUser.birthday.split('.').reverse().join('-')),
      location: currentUser.location.addressName,
      aboutMe: currentUser.aboutMe,
      salaryLevel: returnCategory(currentUser.userEventID, mentorsCategory),
      employment: currentUser.background.employment,
      competence: currentUser.background.competence,
    },
    validateInputOnBlur: true,
  })

  const handleSubmit = (values: any) => {
    const user = {
      ...values,
      birthday: formatDate(values.birthday),
      salaryLevel: returnCategoryId(values.salaryLevel, mentorsCategory),
    }
    isEditUser(false)
    updateUsersKy(navigate, currentUser, user)
  }
  return (
    <ScrollArea type='always' scrollbarSize={8} scrollHideDelay={0}>
      <form
        onSubmit={form.onSubmit((values) => {
          handleSubmit(values)
        })}
      >
        <Flex p={12} gap={40} direction='column' w='100%'>
          <UserInfo
            formattedDate={formattedDate}
            firstName={currentUser.firstName}
            middleName={currentUser.middleName}
            lastName={currentUser.lastName}
          />
          <Flex w='100%'>
            <Flex direction='column'>
              {nameTableUserInfo.map((item) => {
                return (
                  <Flex key={item} h={56} w={200} p={12}>
                    <Text c='#8F8F8F'>{item}</Text>
                  </Flex>
                )
              })}
            </Flex>
            <Flex direction='column'>
              <Flex h={56} p={12}>
                <Text>{currentUser.userID}</Text>
              </Flex>
              {editUser ? (
                <Flex direction='column' gap={10}>
                  <CustomDatePicker
                    initialValue={
                      new Date(currentUser.birthday.split('.').reverse().join('-'))
                    }
                    label='Дата рождения'
                    key={form.key('birthday')}
                    {...form.getInputProps('birthday')}
                  />
                  {/* <DatePickerInput
                    label='Дата рождения'
                    key={form.key('birthday')}
                    {...form.getInputProps('birthday')}
                    radius={8}
                    placeholder='Дата рождения'
                  /> */}
                  <TextInput
                    label='Местоположение'
                    key={form.key('location')}
                    {...form.getInputProps('location')}
                    placeholder='Местоположение'
                    radius={8}
                  />
                  <Textarea
                    label='О себе'
                    key={form.key('aboutMe')}
                    {...form.getInputProps('aboutMe')}
                    w='100%'
                    radius={8}
                    placeholder='О себе'
                  />
                  <Select
                    label='Уровень прибыли'
                    key={form.key('salaryLevel')}
                    {...form.getInputProps('salaryLevel')}
                    radius={8}
                    placeholder='Уровень прибыли'
                    data={[...mentorsCategoryName]}
                  />
                  <Textarea
                    label='Ниша'
                    key={form.key('employment')}
                    {...form.getInputProps('employment')}
                    w='100%'
                    radius={8}
                    placeholder='Ниша'
                  />
                  <Textarea
                    label='Компитенции'
                    key={form.key('competence')}
                    {...form.getInputProps('competence')}
                    w='100%'
                    radius={8}
                    placeholder='Компитенции'
                  />
                  <Button type='submit' color='black' radius={8}>
                    Отправить
                  </Button>
                </Flex>
              ) : (
                <Flex h={56} p={12}>
                  <Text>{currentUser.birthday}</Text>
                </Flex>
              )}
              {editUser ? null : (
                <Flex h={56} p={12}>
                  <Text>{`${currentUser.location.addressName} ${currentUser.location.countryName} ${currentUser.location.cityName} ${currentUser.location.districtName}`}</Text>
                </Flex>
              )}

              {editUser ? null : (
                <>
                  <Flex h={56} p={12}>
                    <Text>{getContactByType(2, currentUser.contactInfo)}</Text>
                  </Flex>

                  <Flex h={56} p={12}>
                    <Text>{getContactByType(3, currentUser.contactInfo)}</Text>
                  </Flex>

                  <Flex h={56} p={12}>
                    <Text>{getContactByType(1, currentUser.contactInfo)}</Text>
                  </Flex>

                  <Flex h={56} p={12}>
                    <Text>{getContactByType(4, currentUser.contactInfo)}</Text>
                  </Flex>
                </>
              )}

              {editUser ? null : (
                <Flex p={12}>
                  <Text>{currentUser.aboutMe}</Text>
                </Flex>
              )}

              {editUser ? null : null}
            </Flex>
          </Flex>
          <Flex gap={16} direction='column'>
            <Title fw={500} fz={18}>
              Уровень прибыли
            </Title>
            <Text>{currentUser.userEventName}</Text>
          </Flex>
          <Flex gap={16} direction='column'>
            <Title fw={500} fz={18}>
              Ниша
            </Title>
            <Text>{currentUser.background.employment}</Text>
          </Flex>
          <Flex gap={16} direction='column'>
            <Title fw={500} fz={18}>
              Компетенции
            </Title>
            <Text>{currentUser.background.competence}</Text>
          </Flex>
        </Flex>
      </form>
    </ScrollArea>
  )
}

const UserInfo = ({ firstName, middleName, lastName, formattedDate }: any) => {
  const fileInputRef = useRef(null)
  const currentUser = useStore($currentUser)
  const navigate = useNavigate()
  const userStore = useStore($userStore)
  return (
    <Flex align='center' justify='space-between'>
      <Flex gap={12} align='center'>
        <Avatar
          onClick={() => {
            const newCurrent: any = fileInputRef.current
            newCurrent.click()
          }}
          size={80}
          src={
            isLinkPhoto(currentUser.photo.photoBase64)
              ? currentUser.photo.photoBase64
              : `data:image/png;base64,${currentUser.photo.photoBase64}`
          }
          alt='фото'
          style={{
            cursor: 'pointer',
          }}
        />
        <FileInput
          style={{
            display: 'none',
          }}
          ref={fileInputRef}
          onChange={(file) => {
            convertToBase64(
              file,
              navigate,
              userStore.apiKey,
              currentUser.userID,
              userStore.userID
            )
          }}
        />
        <Text fz={24} fw={500}>
          {`${lastName} ${firstName} ${middleName}`}
        </Text>
        <ActionIcon
          color='white'
          onClick={() => {
            isEditUser(true)
          }}
        >
          <EditIcon />
        </ActionIcon>
      </Flex>
      <Text fz={14} fw={400} c='#8F8F8F'>
        Зарегистрирован {formattedDate}
      </Text>
    </Flex>
  )
}
