import { Button, Flex } from '@mantine/core'
import { useStore } from 'effector-react'
// eslint-disable-next-line import/no-internal-modules
import { ArrowLeft } from 'shared/asset'
import { $stepForm, prevStep } from '../model'
import { Form2FA } from './form-2fa'
import { FormСonfirmationСode } from './form-confirmation-code'
import { FormInviteToken } from './form-invite-token'
import { FormPassword } from './form-password'
import { FormPhoneAndEmail } from './form-phone-and-email'

// многошаговые формы
const MultiStepForm = () => {
  const store = useStore($stepForm)

  const returnForm = (step: string) => {
    if (step === 'FormСonfirmationСodePhone') {
      return <FormСonfirmationСode length={4} voice={false} />
    } else if (step === 'FormСonfirmationСodeMessage') {
      return <FormСonfirmationСode length={6} voice={true} />
    } else if (step === 'FormСonfirmationСodePhoneVoice') {
      return <FormСonfirmationСode length={4} voice={true} />
    } else if (step === 'FormPassword') {
      return <FormPassword />
    } else if (step === 'FormInviteToken') {
      return <FormInviteToken />
    } else if (step === 'PasswordRecovery') {
      return <FormPhoneAndEmail title='Восстановление' />
    } else if (step === 'Form2FA') {
      return <Form2FA />
    } else {
      return <FormPhoneAndEmail title='Вход' />
    }
  }

  return (
    <Flex gap={40} direction='column'>
      <Button
        display={store.length > 1 ? 'flex' : 'none'}
        onClick={() => {
          prevStep()
        }}
        p={0}
        w={120}
        h={26}
        fz={18}
        fw={400}
        size='compact-md'
        bg='white'
        c='#8F8F8F'
        leftSection={<ArrowLeft />}
      >
        Назад
      </Button>
      {returnForm(store[store.length - 1])}
    </Flex>
  )
}

export default MultiStepForm
