import { Avatar, Flex, Text, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useStore } from 'effector-react'
import { $userStore, setMyInfo } from 'entities'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
// eslint-disable-next-line import/no-internal-modules
import { ArrowDown } from 'shared/asset'
// eslint-disable-next-line boundaries/element-types
import EventsTable from 'widgets/events-table'
import { AlertDescription } from '../alert-decription'
import ButtonsSettings from '../buttons-settings'
import { CreateEvent } from '../create-event'
import { DrawerInfo } from '../drawer-info'
import { PaginationButtons } from '../pagination-buttons'
import { SearchInput } from '../search'
// eslint-disable-next-line boundaries/element-types
import { getAllRolesKy, getCurrentUserKy } from 'shared/api/users'
import { HeaderProps, TableContentProps, UserInfoProps } from 'shared/lib/shared-types'
// eslint-disable-next-line boundaries/element-types
import { isLinkPhoto } from 'shared/lib/is-link-photo'
// eslint-disable-next-line boundaries/element-types
import UsersTable from 'widgets/users-table'
import FilterEvent from '../filterEvent'
import SwitchingPages from '../switching-pages'

export const ContentPage = () => {
  const [opened, { open, close }] = useDisclosure(false)
  const navigate = useNavigate()
  const userStore = useStore($userStore)

  useEffect(() => {
    getAllRolesKy(navigate)
  }, [])
  return (
    <Flex w='100dvw' h='100dvh'>
      <SidebarTable />
      <Flex direction='column' p={48} w='100%' h='100%'>
        <Header open={open} />
        <TableContent open={open} />
      </Flex>
      <DrawerInfo opened={opened} close={close} />
      <AlertDescription />
    </Flex>
  )
}

const Header = ({ open }: HeaderProps) => {
  const { pathname } = useLocation()

  const returnTitle = () => {
    if (pathname === '/users') {
      return 'Пользователи'
    } else {
      return 'События'
    }
  }

  return (
    <Flex mb={20} justify='space-between' w='100%'>
      <Title fz={32} fw={500}>
        {returnTitle()}
      </Title>
      <UserInfo open={open} />
    </Flex>
  )
}

const UserInfo = ({ open }: UserInfoProps) => {
  const navigate = useNavigate()
  const userStore = useStore($userStore)
  return (
    <Flex
      onClick={() => {
        setMyInfo(true)
        open()
        getCurrentUserKy(navigate, userStore.userID)
      }}
      gap={8}
      align='center'
      style={{
        cursor: 'pointer',
      }}
    >
      <Avatar
        src={
          isLinkPhoto(userStore.photo)
            ? userStore.photo
            : `data:image/png;base64,${userStore.photo}`
        }
        alt='фото'
      />
      <Text fz={16} fw={500}>
        {`${userStore.firstName} ${userStore.lastName}`}
      </Text>
      <ArrowDown />
    </Flex>
  )
}

const TableContent = ({ open }: TableContentProps) => {
  const { pathname } = useLocation()

  const returnTable = () => {
    if (pathname === '/users') {
      return <UsersTable open={open} />
    } else {
      return <EventsTable open={open} />
    }
  }

  return (
    <Flex w='100%' h='100%' direction='column' gap={16}>
      <Flex justify='space-between'>
        <Flex align='center' gap={10}>
          <SearchInput />
          {pathname === '/events' ? <FilterEvent /> : <Flex></Flex>}
        </Flex>
        {pathname === '/events' ? <CreateEvent /> : <Flex></Flex>}
      </Flex>
      <Flex w='100%' h='90%'>
        {returnTable()}
      </Flex>

      <PaginationButtons />
    </Flex>
  )
}

const SidebarTable = () => {
  return (
    <Flex
      w={320}
      h='100%'
      bg='#F9F9F9'
      p='40px 30px'
      direction='column'
      justify='space-between'
    >
      <SwitchingPages />
      <ButtonsSettings />
    </Flex>
  )
}
