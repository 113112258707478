import {
  ActionIcon,
  Flex,
  Group,
  HoverCard,
  Loader,
  Menu,
  Table,
  Text,
} from '@mantine/core'
import { useStore } from 'effector-react'
// eslint-disable-next-line import/no-internal-modules
import {
  $dateFilter,
  $loaderEvents,
  $myEventsFilter,
  $searchText,
  $typeFilter,
  $userStore,
  setMyInfo,
} from 'entities'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { getCurrentEventKy } from 'shared/api/events'
// eslint-disable-next-line import/no-internal-modules
import { Dotted, DottedMin, Trasher } from 'shared/asset'
import { isDatePassed } from 'shared/lib/is-date-passed'
import { $events } from '../model'
import { Event, MenuInfoAndDeleteProps, UsersTableProps } from '../types'
import { deleteEvent, getEventsKy } from '../utils'

const EventsTable = ({ open }: UsersTableProps) => {
  const loader = useStore($loaderEvents)
  const events = useStore($events)
  const usersStore = useStore($userStore)
  const searchText = useStore($searchText)
  const typeFilter = useStore($typeFilter)
  const dateFilter = useStore($dateFilter)
  const myEventsFilter = useStore($myEventsFilter)
  const navigate = useNavigate()

  useEffect(() => {
    getEventsKy(navigate, 1, searchText, typeFilter, dateFilter, myEventsFilter)
  }, [searchText, typeFilter, dateFilter, myEventsFilter])

  const stringAbbreviation = (str: string) => {
    if (str.length > 59) {
      return `${str.slice(0, 58)}...`
    } else {
      return str
    }
  }

  const newId = (id: number, date: string) => {
    return id + date
  }

  const rows = events.map((event: Event) => {
    return (
      <Table.Tr
        style={{
          borderBottom: '1px solid #e0e0e0',
          cursor: 'pointer',
        }}
        key={newId(event.eventID, event.date[0].dateFrom)}
      >
        <Table.Td
          onClick={() => {
            setMyInfo(false)
            open()
            getCurrentEventKy(
              navigate,

              event.date[0].dateFrom,
              event.eventID
            )
          }}
        >
          {event.eventID}
        </Table.Td>
        <Table.Td
          onClick={() => {
            setMyInfo(false)
            open()
            getCurrentEventKy(
              navigate,

              event.date[0].dateFrom,
              event.eventID
            )
          }}
        >
          {stringAbbreviation(event.eventName)}
          {/* {event.eventName} */}
        </Table.Td>
        <Table.Td
          onClick={() => {
            setMyInfo(false)
            open()
            getCurrentEventKy(navigate, event.date[0].dateFrom, event.eventID)
          }}
        >
          {event.categoryName}
        </Table.Td>
        <Table.Td
          onClick={() => {
            setMyInfo(false)
            open()
            getCurrentEventKy(
              navigate,

              event.date[0].dateFrom,
              event.eventID
            )
          }}
        >
          {stringAbbreviation(event.location.addressName)}
        </Table.Td>
        <Table.Td
          onClick={() => {
            setMyInfo(false)
            open()
            getCurrentEventKy(navigate, event.date[0].dateFrom, event.eventID)
          }}
        >
          {event.date[0].dateFrom}
        </Table.Td>
        <Table.Td
          onClick={() => {
            setMyInfo(false)
            open()
            getCurrentEventKy(
              navigate,

              event.date[0].dateFrom,
              event.eventID
            )
          }}
        >
          {event.typeName}
        </Table.Td>

        <Table.Td
          onClick={() => {
            setMyInfo(false)
            open()
            getCurrentEventKy(
              navigate,

              event.date[0].dateFrom,
              event.eventID
            )
          }}
        >
          {`${event.comingCount.students}/${event.registeredCount.students}/${
            event.limits.students ? event.limits.students : '-'
          }`}
        </Table.Td>
        <Table.Td
          onClick={() => {
            setMyInfo(false)
            open()
            getCurrentEventKy(
              navigate,

              event.date[0].dateFrom,
              event.eventID
            )
          }}
        >
          <Flex
            align='center'
            justify='center'
            p='4px 8px'
            bg={isDatePassed(event.date[0].dateFrom) ? '#FFECEA' : '#EAFFEE'}
            c={isDatePassed(event.date[0].dateFrom) ? '#F24B3B' : '#48BE62'}
            style={{
              borderRadius: '36px',
            }}
          >
            {isDatePassed(event.date[0].dateFrom) ? 'Запланированно' : 'Проведено'}
          </Flex>
        </Table.Td>

        <Table.Td>
          <MenuInfoAndDelete
            eventID={event.eventID}
            dateFrom={event.date[0].dateFrom}
            apiKey={usersStore.apiKey}
            open={open}
          />
        </Table.Td>
      </Table.Tr>
    )
  })
  return (
    <>
      {loader ? (
        <Flex w='100%' h='100%' align='center' justify='center'>
          <Loader size={80} />
        </Flex>
      ) : (
        <Table
          style={{
            height: '54px',
          }}
        >
          <Table.Thead>
            <Table.Tr
              bg='#F9F9F9'
              style={{
                border: 'none',
              }}
            >
              <Table.Th>ID</Table.Th>
              <Table.Th>Наименование</Table.Th>
              <Table.Th>Тип</Table.Th>
              <Table.Th>Местоположение</Table.Th>
              <Table.Th>Дата</Table.Th>
              <Table.Th>Формат</Table.Th>
              <Table.Th>
                <Group justify='center'>
                  <HoverCard width={280} shadow='md'>
                    <HoverCard.Target>
                      <Text
                        fz={14}
                        fw={700}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        Участники
                      </Text>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Text size='sm'>пришло/зарегалось/лимит</Text>
                    </HoverCard.Dropdown>
                  </HoverCard>
                </Group>
              </Table.Th>
              {/* <Table.Th>From bitrix</Table.Th> */}
              <Table.Th>Статус</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      )}
    </>
  )
}

const MenuInfoAndDelete = ({
  eventID,
  dateFrom,
  apiKey,
  open,
}: MenuInfoAndDeleteProps) => {
  const navigate = useNavigate()
  const typeFilter = useStore($typeFilter)
  const dateFilter = useStore($dateFilter)
  const myEventsFilter = useStore($myEventsFilter)

  return (
    <Menu shadow='md' width={200}>
      <Menu.Target>
        <ActionIcon color='white' variant='filled'>
          <Dotted />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          leftSection={<DottedMin />}
          onClick={() => {
            setMyInfo(false)
            open()
            getCurrentEventKy(navigate, dateFrom, eventID)
          }}
        >
          Подробнее
        </Menu.Item>

        <Menu.Item
          c='#EB4D3D'
          leftSection={<Trasher />}
          onClick={() => {
            deleteEvent(navigate, eventID, typeFilter, dateFilter, myEventsFilter)
          }}
        >
          Удалить событие
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export default EventsTable
