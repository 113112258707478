import { MantineProvider } from '@mantine/core'
import '@mantine/core/styles.css'
import Router from './router'

const App = () => {
  return (
    <MantineProvider>
      <Router />
    </MantineProvider>
  )
}

export default App
