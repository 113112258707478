import { Button, Flex, Loader, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useStore } from 'effector-react'
import { $loaderStore, isLoader, setAlertState } from 'entities'
import ky from 'shared/api/ky'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
import { nextStep } from '../model'

export const FormInviteToken = () => {
  const loaderStore = useStore($loaderStore)

  const form = useForm({
    initialValues: {
      inviteToken: '',
    },
    validateInputOnBlur: true,
    validate: {
      inviteToken: (value) => (/^.+$/.test(value) ? false : 'false'),
    },
  })

  const handleSubmit = async (values: string) => {
    const res = await ky.post('invite/use_invite_token', {
      json: {
        inviteToken: values,
      },
    })

    if (res.status === 400) {
      setAlertState('use_invite_code 400')
      isLoader()
      return
    }

    if (res.status === 401) {
      removeItemAndExit()
      setAlertState('use_invite_code 401')
      isLoader()
      return
    }

    if (res.status === 412) {
      removeItemAndExit()
      setAlertState('use_invite_code 412')
      isLoader()
      return
    }

    if (res.status === 500) {
      isLoader()
      form.setErrors({ inviteToken: 'Не валидный invite token' })
      return
    }

    if (res.status === 200) {
      isLoader()
      localStorage.setItem('invite', 'true')
      nextStep('Form2FA')
      return
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        isLoader()
        handleSubmit(values.inviteToken)
      })}
    >
      <Flex w='80%' gap={20} direction='column'>
        <Title fz={42} fw={600}>
          Введите инвайт токен
        </Title>
        <Text c='#8F8F8F' fz={18} fw={400}>
          Чтобы подтвердить права администратора, нужно ввести инвайт код. Если у вас нет
          прав администратора, вы можете запросить их через службу поддержки.
        </Text>

        <Flex gap={40} direction='column'>
          <TextInput
            withAsterisk
            key={form.key('inviteToken')}
            {...form.getInputProps('inviteToken')}
            size='lg'
            w='100%'
            placeholder='Инвайт токен'
          />
          <Button
            disabled={!form.isValid()}
            type='submit'
            size='lg'
            radius={12}
            variant='filled'
            color='black'
          >
            {loaderStore ? <Loader color='white' /> : 'Продолжить'}
          </Button>
        </Flex>
      </Flex>
    </form>
  )
}
