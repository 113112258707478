import { Button, Flex, Loader, PinInput, Text, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useStore } from 'effector-react'
import {
  $loaderStore,
  $userStore,
  isLoader,
  newApiKey,
  newEmail,
  newEmailHash,
  newPhone,
  newPhoneHash,
  newRole,
  newTOTP,
  setAlertState,
  setFirstName,
  setLastName,
  setPhoto,
  setUserID,
} from 'entities'
import { useEffect, useState } from 'react'
import ky from 'shared/api/ky'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
import { $stepForm, nextStep } from '../model'
import { FormСonfirmationСodeProps } from './../index'
import { ModalAnotherLoginOption } from './modal-another-login-option'

export const FormСonfirmationСode = ({ length, voice }: FormСonfirmationСodeProps) => {
  const [opened, { open, close }] = useDisclosure(false)
  const userStore = useStore($userStore)
  const loaderStore = useStore($loaderStore)
  const stepForm = useStore($stepForm)
  const [pinError, setPinError] = useState(false)

  const handlePinChange = (value: string) => {
    setPinError(false)
    // eslint-disable-next-line functional/no-conditional-statements
    if (value.length === length) {
      isLoader()
      handleSubmit(value)
    }
  }

  const handleSubmit = async (value: string) => {
    const res = await ky.post('auth/sign_in_verify', {
      json: {
        code: Number(value),
        contact: length === 4 ? userStore.phoneHash : userStore.emailHash,
      },
    })

    if (res.status === 400) {
      setAlertState('sign_in_verify 400')
      isLoader()
      return
    }

    if (res.status === 401) {
      removeItemAndExit()
      setAlertState('sign_in_verify 401')
      isLoader()
      return
    }

    if (res.status === 412) {
      removeItemAndExit()
      setAlertState('sign_in_verify 412')
      isLoader()
      return
    }

    if (res.status === 500) {
      isLoader()
      setPinError(true)
      return
    }

    if (res.status === 200) {
      isLoader()
      const data: any = await res.json()
      // eslint-disable-next-line functional/no-let
      let roleBoolean = false
      // eslint-disable-next-line functional/no-loop-statements
      for (const item of data.userInfo.userRoles) {
        // eslint-disable-next-line functional/no-conditional-statements
        if (item.permission === 1 || item.permission === 7 || item.permission === 8) {
          roleBoolean = true
        }
      }
      newApiKey(data.apiKey)
      setUserID(data.userInfo.userID)
      setFirstName(data.userInfo.firstName)
      setLastName(data.userInfo.lastName)
      setPhoto(data.userInfo.photo ? data.userInfo.photo.photoBase64 : '')
      localStorage.setItem(
        'photo',
        data.userInfo.photo ? data.userInfo.photo.photoBase64 : ''
      )
      localStorage.setItem('apiKey', data.apiKey)
      localStorage.setItem('userID', data.userInfo.userID)
      localStorage.setItem('firstName', data.userInfo.firstName)
      localStorage.setItem('lastName', data.userInfo.lastName)
      newRole(roleBoolean)
      newTOTP(data.userInfo.useTOTP)
      if (roleBoolean) {
        localStorage.setItem('invite', 'true')
        nextStep('Form2FA')
        return
      } else {
        nextStep('FormInviteToken')
        return
      }
      return
    }
  }

  const [time, setTime] = useState(59)
  const [isTimerActive, setIsTimerActive] = useState(true)

  useEffect(() => {
    // eslint-disable-next-line functional/no-let
    let interval = null

    // eslint-disable-next-line functional/no-conditional-statements
    if (isTimerActive) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1)
      }, 1000)
    }

    return () => {
      // eslint-disable-next-line functional/no-conditional-statements
      if (interval !== null) {
        clearInterval(interval)
      }
    }
  }, [isTimerActive])

  useEffect(() => {
    if (time === 0) {
      setIsTimerActive(false)
      return
    }
  }, [time])

  const handleResendClick = () => {
    onsubmitSignIn(
      stepForm[stepForm.length - 1] === 'FormСonfirmationСodeMessage'
        ? userStore.emailHash
        : userStore.phoneHash,
      voice
    )
    setTime(59)
    setIsTimerActive(true)
  }

  return (
    <Flex w='80%' gap={20} direction='column'>
      <Title fz={42} fw={600}>
        Введите код подтверждения
      </Title>
      {length === 4 ? (
        <Text fw={400} fz={18} c='#8F8F8F'>
          На ваш номер{' '}
          <Text display='inline' fw={600} c='black'>
            {userStore.phone}
          </Text>{' '}
          поступит звонок,
          {voice
            ? 'введите код, который продиктует робот'
            : 'введите 4 последние цифры номера'}
        </Text>
      ) : (
        <Text fw={400} fz={18} c='#8F8F8F'>
          На вашу почту{' '}
          <Text display='inline' fw={600} c='black'>
            {userStore.email}
          </Text>{' '}
          отправлен одноразовый код подтверждения
        </Text>
      )}

      <PinInput
        onChange={handlePinChange}
        size='xl'
        length={length}
        placeholder=''
        type='number'
        error={pinError}
      />

      {isTimerActive ? (
        <Text c='#8F8F8F'>
          Отправить ещё раз через {`0:${time.toString().padStart(2, '0')}`}
        </Text>
      ) : (
        <Text
          style={{
            cursor: 'pointer',
          }}
          c='#1681FF'
          onClick={handleResendClick}
        >
          Отправить ещё раз
        </Text>
      )}

      {loaderStore ? (
        <Flex align='center' justify='center'>
          <Loader />
        </Flex>
      ) : (
        <Button onClick={open} c='blue' bg='white' w={340}>
          Подтвердить другим способом
        </Button>
      )}

      <ModalAnotherLoginOption
        setTime={setTime}
        setIsTimerActive={setIsTimerActive}
        opened={opened}
        close={close}
      />
    </Flex>
  )
}

const onsubmitSignIn = async (values: string, voice: boolean) => {
  const res = await ky.post('auth/sign_in', {
    json: {
      contact: values,
      useVoice: voice,
    },
  })

  if (res.status === 400) {
    setAlertState('sign_in_verify 400')
    return
  }

  if (res.status === 401) {
    removeItemAndExit()
    setAlertState('sign_in_verify 401')
    return
  }

  if (res.status === 412) {
    removeItemAndExit()
    setAlertState('sign_in_verify 412')
    return
  }

  if (res.status === 500) {
    setAlertState('sign_in_verify 500')
    return
  }

  if (res.status === 200) {
    const data: any = await res.json()
    const emailBool = data.contactInfoDecoded[0].name === 'email'
    newPhone(data.contactInfoDecoded[emailBool ? 1 : 0].contact)
    newPhoneHash(data.contactInfoDecoded[emailBool ? 1 : 0].contactHash)
    newEmail(data.contactInfoDecoded[emailBool ? 0 : 1].contact)
    newEmailHash(data.contactInfoDecoded[emailBool ? 0 : 1].contactHash)
    return
  }
}
