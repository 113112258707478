import { createStore } from 'effector'

import { UserState } from 'widgets/multi-step-form'
import {
  isLoader,
  isLoaderEvents,
  newApiKey,
  newEmail,
  newEmailHash,
  newPhone,
  newPhoneHash,
  newRole,
  newTOTP,
  setCurrentInviteToken,
  setDateFilter,
  setFirstName,
  setLastName,
  setMyEventsFilter,
  setPhoto,
  setSearchText,
  setTypeFilter,
  setUserID,
} from './events'

export const $userStore = createStore<UserState>({
  firstName: localStorage.getItem('firstName') ? localStorage.getItem('firstName') : '',
  lastName: localStorage.getItem('lastName') ? localStorage.getItem('lastName') : '',
  phone: '',
  phoneHash: '',
  email: '',
  emailHash: '',
  role: false,
  useTOTP: false,
  apiKey: localStorage.getItem('apiKey') ? localStorage.getItem('apiKey') : '',
  userID: localStorage.getItem('userID') ? localStorage.getItem('userID') : '',
  photo: localStorage.getItem('photo') ? localStorage.getItem('photo') : '',
})
  .on(newPhone, (state, phone) => ({
    ...state,
    phone: phone,
  }))
  .on(newPhoneHash, (state, phoneHash) => ({
    ...state,
    phoneHash: phoneHash,
  }))
  .on(newEmail, (state, email) => ({
    ...state,
    email: email,
  }))
  .on(newEmailHash, (state, emailHash) => ({
    ...state,
    emailHash: emailHash,
  }))
  .on(newRole, (state, role) => ({
    ...state,
    role: role,
  }))
  .on(newTOTP, (state, useTOTP) => ({
    ...state,
    useTOTP: useTOTP,
  }))
  .on(newApiKey, (state, apiKey) => ({
    ...state,
    apiKey: apiKey,
  }))
  .on(setUserID, (state, userID) => ({
    ...state,
    userID: userID,
  }))
  .on(setFirstName, (state, firstName) => ({
    ...state,
    firstName: firstName,
  }))
  .on(setLastName, (state, setLastName) => ({
    ...state,
    setLastName: setLastName,
  }))
  .on(setPhoto, (state, photo) => ({
    ...state,
    photo: photo,
  }))

export const $currentInviteToken = createStore<string>('').on(
  setCurrentInviteToken,
  (state, token) => token
)

export const $loaderEvents = createStore<boolean>(false).on(
  isLoaderEvents,
  (state) => !state
)

export const $loaderStore = createStore<boolean>(false).on(isLoader, (state) => !state)

export const $searchText = createStore<string>('').on(
  setSearchText,
  (state, text) => text
)

export const $typeFilter = createStore<number[]>([]).on(
  setTypeFilter,
  (state, typeFilterArr) => typeFilterArr
)

export const $dateFilter = createStore<Array<string | null>>([]).on(
  setDateFilter,
  (state, date) => date
)

export const $myEventsFilter = createStore<boolean>(false).on(
  setMyEventsFilter,
  (state, myEvents) => myEvents
)
