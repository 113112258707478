import { createStore } from 'effector'
import { isLoaderUsersRegistered, setCurrentEvent } from './events'
// eslint-disable-next-line boundaries/element-types
import { Event, mockEvent, returnCorrectEvent } from 'widgets/events-table'

export const $loaderUsersRegistered = createStore<boolean>(false).on(
  isLoaderUsersRegistered,
  (state, event) => !state
)

export const $currentEvent = createStore<Event>(mockEvent).on(
  setCurrentEvent,
  (state, event) => returnCorrectEvent(mockEvent, event)
)
