import { createEvent } from 'effector'
import { RegitredUser } from '../types'

export const setSearchTurn = createEvent<string>()

export const setStateDrawerTable = createEvent<string>()

export const isEdit = createEvent<boolean>()

export const setUsersRegistered = createEvent<RegitredUser[]>()
