import { Drawer, Flex, Loader } from '@mantine/core'
import { useStore, useUnit } from 'effector-react'
import { $loaderStore } from 'entities'
import { $currentUser } from 'entities/drawer-user-info'
import { BodyDriwer } from './body-driwer'
import { HeaderDrawer } from './header-drawer'

const DrawerUserInfo = ({ opened, close }: any) => {
  const loader = useStore($loaderStore)
  const currentUser = useUnit($currentUser)

  return (
    <Drawer size={796} position='right' opened={opened} onClose={close}>
      {loader ? (
        <Flex w='100%' h='100%' align='center' justify='center'>
          <Loader />
        </Flex>
      ) : (
        <Flex direction='column' w='100%' h='100%'>
          <HeaderDrawer id={currentUser.userID} roles={currentUser.userRoles} />
          <BodyDriwer />
        </Flex>
      )}
    </Drawer>
  )
}

export default DrawerUserInfo
