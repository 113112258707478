import { TextInput } from '@mantine/core'
import { setSearchText } from 'entities'
import React, { useEffect, useRef, useState } from 'react'
// eslint-disable-next-line import/no-internal-modules
import { Search } from 'shared/asset'

export const SearchInput: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    // eslint-disable-next-line functional/no-conditional-statements
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    // eslint-disable-next-line functional/immutable-data
    timerRef.current = setTimeout(() => {
      setSearchText(searchTerm)
    }, 0)

    return () => {
      // eslint-disable-next-line functional/no-conditional-statements
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [searchTerm])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  return (
    <TextInput
      value={searchTerm}
      radius={8}
      w={484}
      leftSectionPointerEvents='none'
      leftSection={<Search />}
      placeholder='Search'
      onChange={handleInputChange}
    />
  )
}
