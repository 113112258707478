import { Flex, Loader, PinInput, Text, Title } from '@mantine/core'
import { useStore } from 'effector-react'
import { $loaderStore, $userStore, isLoader, newTOTP, setAlertState } from 'entities'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import ky from 'shared/api/ky'
import { isLinkPhoto } from 'shared/lib/is-link-photo'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
import { $pinError, $qr, setPinError, setQr } from '../model'

export const Form2FA = () => {
  const userStore = useStore($userStore)
  const loaderStore = useStore($loaderStore)
  const navigate = useNavigate()
  const qr = useStore($qr)
  const pinError = useStore($pinError)

  useEffect(() => {
    if (userStore.useTOTP === false) {
      getQr()
      return
    }
  }, [])

  const handlePinChange = (value: string) => {
    setPinError(false)

    if (value.length === 6) {
      isLoader()
      send2FA(navigate, value)
      return
    }
  }

  return (
    <Flex w='80%' gap={20} direction='column'>
      <Title fz={42} fw={600}>
        Подтверждение входа
      </Title>

      {userStore.useTOTP ? (
        <Text fz={18} fw={400} c='#8F8F8F'>
          Для вашего профиля включена двухфакторная аутентификация. Введите код из Google
          Authentificator или другого выбранного вами приложения.
        </Text>
      ) : (
        <Flex w='100%' gap={20} direction='column'>
          <Flex w='100%' align='center' justify='center'>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={isLinkPhoto(qr) ? qr : `data:image/png;base64,${qr}`}
              alt='Base64 Image'
              style={{ width: '200px' }}
            />
          </Flex>

          <Text fw={400} fz={18}>
            Сначала убедитесь, что на вашем телефоне установлено приложение для работы с
            одноразовыми кодами (Google Authenticator, Яндекс.Ключ и т.д.).
          </Text>
          <Text fw={400} fz={18}>
            Затем наведите камеру на QR-код. После этого вы будете перенаправлены в
            приложение. В Google Authenticator или другом выбранном вами приложении
            появится ваш аккаунт с обновляемым кодом для авторизации. Появившийся код
            введите ниже.
          </Text>
        </Flex>
      )}

      <PinInput
        onChange={handlePinChange}
        size='xl'
        length={6}
        placeholder=''
        type='number'
        error={pinError}
      />
      {loaderStore ? (
        <Flex align='center' justify='center'>
          <Loader />
        </Flex>
      ) : (
        <Text fz={18} fw={400} c='#A7A6AD'>
          Если вы не можете ввести код, пожалуйста, обратитесь в службу поддержки
        </Text>
      )}
    </Flex>
  )
}

const getQr = async () => {
  const res = await ky.post('auth/init_totp', {})

  if (res.status === 400) {
    setAlertState('init_totp 400')
    return
  }

  if (res.status === 401) {
    removeItemAndExit()
    setAlertState('init_totp 401')
    return
  }

  if (res.status === 412) {
    removeItemAndExit()
    setAlertState('init_totp 412')
    return
  }

  if (res.status === 500) {
    setAlertState('init_totp 500')
    return
  }

  if (res.status === 200) {
    const data: any = await res.json()
    setQr(data.QRLinkBase64)
    return
  }
}

const send2FA = async (navigate: any, value: string) => {
  newTOTP(true)
  const res = await ky.post('auth/verify_totp', {
    json: {
      totpCode: value,
    },
  })

  if (res.status === 400) {
    setAlertState('verify_totp 400')
    isLoader()
    return
  }

  if (res.status === 401) {
    setAlertState('verify_totp 401')
    isLoader()
    return
  }

  if (res.status === 412) {
    setAlertState('verify_totp 412')
    isLoader()
    return
  }

  if (res.status === 500) {
    isLoader()
    setPinError(true)
    return
  }

  if (res.status === 200) {
    isLoader()
    const data = await res.json()
    localStorage.setItem('2fa', 'true')
    navigate('/users')
    window.location.reload()
    return
  }
}
