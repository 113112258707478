import { createStore } from 'effector'
import { Role } from 'shared/lib/shared-types'
import { setAllRoles, setCurrentRole } from './events'

export const $currentRole = createStore<Role>({
  id: 4,
  name: 'Студент',
  canCreateInvite: false,
  priority: 0,
}).on(setCurrentRole, (state, role) => role)

export const $roles = createStore<Role[]>([]).on(setAllRoles, (state, roles) => roles)
