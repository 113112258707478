import {
  ActionIcon,
  CopyButton,
  Flex,
  Loader,
  Modal,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useStore } from 'effector-react'
import { $currentEvent, $currentInviteToken, $loaderStore, $userStore } from 'entities'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
// eslint-disable-next-line import/no-internal-modules
import { Copy, PlusWhite, Search } from 'shared/asset'
import { setSearchTurn, setStateDrawerTable } from '../model'
import { ButtonParticipantsVolunteersProps } from '../types'
import { getRegistredUsersKy, getTokenEvents } from '../utils'
import { TableParticipantsAndVolunteers } from './table-participants-and-volunteers'

export const AttendanceTable = ({ stateParticipantsAndVolunteers }: any) => {
  const currentEvent = useStore($currentEvent)
  const navigate = useNavigate()
  const [opened, { open, close }] = useDisclosure(false)
  const dataButtonDrawerTable = [
    {
      id: 1,
      name: 'Участники',
      active: stateParticipantsAndVolunteers === 'participants',
      onClick: () => {
        setStateDrawerTable('participants')
        getRegistredUsersKy(
          navigate,
          currentEvent.date[0].dateFrom,
          currentEvent.eventID,
          4
        )
      },
    },
    {
      id: 2,
      name: 'Волонтеры',
      active: stateParticipantsAndVolunteers === 'volunteers',
      onClick: () => {
        setStateDrawerTable('volunteers')
        getRegistredUsersKy(
          navigate,
          currentEvent.date[0].dateFrom,
          currentEvent.eventID,
          6
        )
      },
    },
  ]

  return (
    <>
      <Flex gap={23} direction='column' w='100%'>
        <Title fz={18} fw={500}>
          Посещаемость
        </Title>
        <Flex gap={8}>
          {dataButtonDrawerTable.map((item) => {
            return (
              <ButtonParticipantsVolunteers
                key={item.id}
                name={item.name}
                active={item.active}
                onClick={item.onClick}
              />
            )
          })}
        </Flex>

        <Flex justify='space-between'>
          <TextInput
            onChange={(e) => {
              setSearchTurn(e.target.value)
            }}
            radius={8}
            w={484}
            leftSectionPointerEvents='none'
            leftSection={<Search />}
            placeholder='Поиск по ФИО'
          />
          <ActionIcon
            onClick={() => {
              open()
            }}
            variant='filled'
            aria-label='Plus'
          >
            <PlusWhite />
          </ActionIcon>
        </Flex>

        <TableParticipantsAndVolunteers />
      </Flex>
      <CreateInviteTokenEvents opened={opened} close={close} />
    </>
  )
}

const ButtonParticipantsVolunteers = ({
  name,
  active,
  onClick,
}: ButtonParticipantsVolunteersProps) => {
  return (
    <Flex
      onClick={() => {
        onClick()
      }}
      bg={active ? '#262626' : 'white'}
      c={active ? 'white' : '#262626'}
      w={119}
      h={36}
      align='center'
      justify='center'
      style={{
        border: '1px solid #262626',
        borderRadius: '65px',
        cursor: 'pointer',
      }}
    >
      {name}
    </Flex>
  )
}

const CreateInviteTokenEvents = ({ opened, close }: any) => {
  return (
    <Modal
      centered
      radius={24}
      size={718}
      opened={opened}
      onClose={() => {
        close()
      }}
    >
      <InviteGenerationEvents />
    </Modal>
  )
}

export const InviteGenerationEvents = () => {
  const currentInviteToken = useStore($currentInviteToken)
  const loader = useStore($loaderStore)
  const userStore = useStore($userStore)
  const currentEvent = useStore($currentEvent)
  const navigate = useNavigate()

  useEffect(() => {
    getTokenEvents(navigate, currentEvent.eventID)
  }, [])

  return (
    <Flex mb={20} p='0px 60px' gap={40} direction='column' w='100%'>
      <Flex direction='column' w='100%'>
        <Title>Генерация инвайт токена</Title>
        <Text>
          Данный токен позволяет назначить пользователю права волонтера. Скопируйте код и
          вышлите...
        </Text>
      </Flex>
      {loader ? (
        <Flex w='100%' h={56} align='center'>
          <Loader />
        </Flex>
      ) : (
        <Flex
          p={15}
          w='100%'
          h={56}
          align='center'
          justify='space-between'
          style={{
            borderRadius: '12px',
            border: '1px solid #BEBEBE',
          }}
        >
          <Text>{currentInviteToken}</Text>
          <CopyButton value={currentInviteToken} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position='right'>
                <ActionIcon
                  color={copied ? 'teal' : 'gray'}
                  variant='subtle'
                  onClick={copy}
                >
                  {copied ? <Copy /> : <Copy />}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Flex>
      )}
    </Flex>
  )
}
