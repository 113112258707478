import { Modal } from '@mantine/core'
import { useStore } from 'effector-react'
import { $stepInviteToken, nextStepInvite } from '../model'
import { ChooseRole } from './choose-role'
import { InviteGeneration } from './invite-generation'

const returnStep = (stepInviteToken: string) => {
  if (stepInviteToken === 'InviteGenerationAdmin') {
    return <InviteGeneration />
  } else {
    return <ChooseRole />
  }
}

const CreateInviteToken = ({ opened, close }: any) => {
  const stepInviteToken = useStore($stepInviteToken)
  return (
    <Modal
      centered
      radius={24}
      size={718}
      opened={opened}
      onClose={() => {
        nextStepInvite('')
        close()
      }}
    >
      {returnStep(stepInviteToken)}
    </Modal>
  )
}

export default CreateInviteToken
