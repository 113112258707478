import { MentorsCategoty } from './shared-types'

export const returnCategoryId = (
  categoryName: string,
  mentorsCategory: MentorsCategoty[]
): number => {
  const category = mentorsCategory.find(
    (category) => category.categoryName === categoryName
  )
  return category ? category.categoryID : 0
}
