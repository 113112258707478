import { ActionIcon, Avatar, Flex, Loader, Table } from '@mantine/core'
import { useStore } from 'effector-react'
import { $currentEvent, $loaderUsersRegistered } from 'entities'
// eslint-disable-next-line import/no-internal-modules
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
// eslint-disable-next-line import/no-internal-modules
import { Dotted } from 'shared/asset'
import { formatString } from 'shared/lib/format-string'
import { getContactByType } from 'shared/lib/get-contact-by-type'
import { isLinkPhoto } from 'shared/lib/is-link-photo'
import { returnStatus } from 'shared/lib/return-status'
import { $searchTurn, $stateParticipantsAndVolunteers, $usersRegistered } from '../model'
import { RegitredUser } from '../types'
import { getRegistredUsersKy } from '../utils'

export const TableParticipantsAndVolunteers = () => {
  const loader = useStore($loaderUsersRegistered)
  const userRegistred = useStore($usersRegistered)
  const searchTerm = useStore($searchTurn)

  function searchByName(userRegistred: RegitredUser[], searchTerm: string) {
    return userRegistred.filter((user) =>
      `${user.userInformation.lastName} ${user.userInformation.firstName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  }

  const currentEvent = useStore($currentEvent)
  const navigate = useNavigate()
  const stateParticipantsAndVolunteers = useStore($stateParticipantsAndVolunteers)

  useEffect(() => {
    getRegistredUsersKy(
      navigate,
      currentEvent.date[0].dateFrom,
      currentEvent.eventID,
      stateParticipantsAndVolunteers === 'participants' ? 4 : 6
    )
  }, [])

  const rows = searchByName(userRegistred, searchTerm).map((user: any) => (
    <Table.Tr
      style={{
        cursor: 'pointer',
        borderBottom: '1px solid #e0e0e0',
      }}
      key={user.userInformation.userID}
    >
      <Table.Td>{formatString(user.userInformation.userID)}</Table.Td>
      <Table.Td>
        <Flex gap={10} align='center'>
          <Avatar
            size={24}
            src={
              isLinkPhoto(user.userInformation.photo.photoBase64)
                ? user.userInformation.photo.photoBase64
                : `data:image/png;base64,${user.userInformation.photo.photoBase64}`
            }
            alt='фото'
          />
          {`${user.userInformation.lastName} ${user.userInformation.firstName}`}
        </Flex>
      </Table.Td>
      <Table.Td>{getContactByType(2, user.userInformation.contactInfo)}</Table.Td>
      <Table.Td>
        <Status status={user.userRegistration[0].statusID} />
      </Table.Td>
      <Table.Td>
        <ActionIcon color='white' variant='filled'>
          <Dotted />
        </ActionIcon>
      </Table.Td>
    </Table.Tr>
  ))

  return (
    <>
      {loader ? (
        <Flex w='100%' h='100%' align='center' justify='center'>
          <Loader size={50} />
        </Flex>
      ) : (
        <Table
          style={{
            height: '54px',
          }}
        >
          <Table.Thead>
            <Table.Tr
              bg='#F9F9F9'
              style={{
                border: 'none',
              }}
            >
              <Table.Th>ID</Table.Th>
              <Table.Th>Пользователь</Table.Th>
              <Table.Th>Имя пользователя</Table.Th>
              <Table.Th>Статус</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      )}
    </>
  )
}

interface StatusPops {
  status: number
}

const Status = ({ status }: StatusPops) => {
  const fields = returnStatus(status)
  return (
    <Flex
      c={fields.color}
      bg={fields.background}
      justify='center'
      align='center'
      style={{
        borderRadius: '36px',
      }}
    >
      {fields.text}
    </Flex>
  )
}
