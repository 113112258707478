import { Drawer, Flex, Loader, ScrollArea, Text, Title } from '@mantine/core'
import { useStore } from 'effector-react'
import { $currentEvent, $loaderEvents } from 'entities'
import { isDatePassed } from 'shared/lib/is-date-passed'
import { $stateParticipantsAndVolunteers } from '../model'
import { AttendanceTable } from './attendance-table'
import { EventsTableInfo } from './events-table-info'

const DrawerEventsInfo = ({ opened, close }: any) => {
  const stateParticipantsAndVolunteers = useStore($stateParticipantsAndVolunteers)
  const loader = useStore($loaderEvents)

  return (
    <Drawer size={796} position='right' opened={opened} onClose={close}>
      {loader ? (
        <Flex w='100%' h='100%' align='center' justify='center'>
          <Loader />
        </Flex>
      ) : (
        <Flex direction='column' w='100%' h='100%'>
          <HeaderEventsInfo />
          <ContentEventsInfo
            stateParticipantsAndVolunteers={stateParticipantsAndVolunteers}
          />
        </Flex>
      )}
    </Drawer>
  )
}

export default DrawerEventsInfo

const HeaderEventsInfo = () => {
  const currentEvent = useStore($currentEvent)

  const currentEventDate = currentEvent.date
    ? currentEvent.date
    : [
        {
          dateFrom: '',
        },
      ]
  return (
    <Flex
      p={24}
      align='center'
      justify='space-between'
      style={{
        borderBottom: '1px solid silver',
      }}
      w='100%'
    >
      <Flex
        p='4px 8px'
        bg={isDatePassed(currentEventDate[0].dateFrom) ? '#FFECEA' : '#EAFFEE'}
        c={isDatePassed(currentEventDate[0].dateFrom) ? '#F24B3B' : '#48BE62'}
        style={{
          borderRadius: '36px',
        }}
      >
        {isDatePassed(currentEventDate[0].dateFrom) ? 'Запланированно' : 'Проведено'}
      </Flex>
    </Flex>
  )
}

const ContentEventsInfo = ({ stateParticipantsAndVolunteers }: any) => {
  return (
    <ScrollArea w='100%' type='always' scrollbarSize={8} scrollHideDelay={0}>
      <Flex p={20} gap={40} direction='column' w='100%'>
        <EventsInformation />
        <AttendanceTable
          stateParticipantsAndVolunteers={stateParticipantsAndVolunteers}
        />
      </Flex>
    </ScrollArea>
  )
}

const EventsInformation = () => {
  const currentEvent = useStore($currentEvent)
  const currentEventCreatedAt = currentEvent.createdAt ? currentEvent.createdAt : ''
  const formattedDate = new Date(currentEventCreatedAt).toLocaleDateString('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })
  return (
    <Flex w='100%' direction='column' gap={28}>
      <Flex justify='space-between'>
        <Flex direction='column'>
          <Title w={500} fz={24} fw={500}>
            {`ID: ${currentEvent.eventID}`}
          </Title>
          <Title w={500} fz={24} fw={500}>
            {currentEvent.eventName}
          </Title>
        </Flex>
        <Text fz={14} fw={400} c='#8F8F8F'>
          {`Создано ${formattedDate}`}
        </Text>
      </Flex>

      <EventsTableInfo />
    </Flex>
  )
}
