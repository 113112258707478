/* eslint-disable functional/no-conditional-statements */
import { Alert } from '@mantine/core'
import { useStore } from 'effector-react'
import { $alertState, setAlertState } from 'entities'
import { useEffect } from 'react'

export const AlertDescription = () => {
  const alertState = useStore($alertState)

  useEffect(() => {
    if (alertState) {
      const timer = setTimeout(() => {
        setAlertState(null)
      }, 1500)
    }
  }, [alertState])

  return (
    <Alert
      style={{
        display: alertState ? 'block' : 'none',
        zIndex: '1000',
        position: 'absolute',
        right: 0,
        top: 0,
        width: '371px',
        height: '92px',
      }}
      variant='filled'
      color='blue'
      title='info'
    >
      {alertState}
    </Alert>
  )
}
