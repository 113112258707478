import { Avatar, Flex } from '@mantine/core'
import { isLinkPhoto } from 'shared/lib/is-link-photo'
import { TableUserProps } from '../types'

export const TableUser = ({ firstName, lastName, img }: TableUserProps) => {
  const avatarImg = img ? img.photoBase64 : ''
  return (
    <Flex gap={8} align='center'>
      <Avatar
        src={isLinkPhoto(avatarImg) ? avatarImg : `data:image/png;base64,${avatarImg}`}
        alt='фото'
      />
      {`${firstName} ${lastName}`}
    </Flex>
  )
}
