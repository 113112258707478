import {
  isLoader,
  isLoaderEvents,
  setAlertState,
  setAllRoles,
  setCurrentUser,
  setTotal,
} from 'entities'
import { debounce } from 'shared/lib/debounce'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
// eslint-disable-next-line import/no-internal-modules, boundaries/element-types
import { setUsers } from 'widgets/users-table/model'
import { getAllCategorysKy } from './auth'
import ky from './ky'

export const getCurrentUserKy = async (navigate: any, userID: string) => {
  isLoader()
  const res = await ky.post('users/fetch_users', {
    json: {
      page: 1,
      limit: 10,
      userID: userID,
    },
  })

  if (res.status === 400) {
    setAlertState('getCurrentUser fetch_users 400')
    isLoader()
    return
  }

  if (res.status === 401) {
    removeItemAndExit()
    navigate('/login')
    isLoader()
    return
  }

  if (res.status === 403) {
    setAlertState('недостаточно прав')
    isLoader()
    return
  }

  if (res.status === 412) {
    removeItemAndExit()
    navigate('/login')
    isLoader()
    return
  }

  if (res.status === 500) {
    setAlertState('getCurrentUser fetch_users 500')
    isLoader()
    return
  }

  if (res.status === 200) {
    const data: any = await res.json()
    setCurrentUser(data.result[0])
    isLoader()
    return
  }
}

export const getUsersKy = debounce(async (navigate: any, page: number, value: string) => {
  isLoader()
  const res = await ky.post(
    'users/fetch_users',
    value !== ''
      ? {
          json: {
            searchName: value,
            limit: 10,
            page: page,
          },
        }
      : {
          json: {
            page: page,
            limit: 10,
          },
        }
  )

  if (res.status === 400) {
    setAlertState('fetch_users 400')
    isLoader()
    return
  }

  if (res.status === 401) {
    removeItemAndExit()
    navigate('/login')
    isLoader()
    return
  }

  if (res.status === 403) {
    setAlertState('недостаточно прав')
    isLoader()
    return
  }

  if (res.status === 412) {
    removeItemAndExit()
    navigate('/login')
    isLoader()
    return
  }

  if (res.status === 500) {
    setAlertState('fetch_users 500')
    isLoader()
    return
  }

  if (res.status === 200) {
    const data: any = await res.json()
    setUsers(data.result)
    setTotal(data.paginator.pageAvailable)
    isLoader()
    return
  }
}, 1000)

export const getAllRolesKy = async (navigate: any) => {
  const res = await ky.post('users/fetch_user_roles', {})

  if (res.status === 400) {
    setAlertState('fetch_user_roles 400')
    isLoaderEvents()
    return
  }

  if (res.status === 401) {
    navigate('/login')
    isLoaderEvents()
    return
  }

  if (res.status === 412) {
    navigate('/login')
    isLoaderEvents()
    return
  }

  if (res.status === 500) {
    setAlertState('fetch_user_roles 500')
    isLoaderEvents()
    return
  }

  if (res.status === 200) {
    const data: any = await res.json()
    setAllRoles(data)
    getAllCategorysKy(navigate)
    return
  }
}
