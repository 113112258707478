import { createStore } from 'effector'
import { deleteTypeArr, setTypeArr } from './events'

export const $typeArr = createStore<number[]>([])
  .on(setTypeArr, (state, typeID) => [...state, typeID])
  .on(deleteTypeArr, (state, typeID) => {
    return state.filter(function (value) {
      return value !== typeID
    })
  })
