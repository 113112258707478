import { isLoaderEvents, setAlertState, setTotal } from 'entities'
import ky from 'shared/api/ky'
import { nullСheck } from 'shared/lib/null-check'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
import { setEvents } from './model'
import { Event } from './types'

function debounce<T extends (...args: any) => any>(
  this: ThisParameterType<T>,
  func: T,
  delay: number
): T {
  // eslint-disable-next-line functional/no-let
  let timeoutId: ReturnType<typeof setTimeout> | null = null

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    // eslint-disable-next-line functional/no-conditional-statements
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      // eslint-disable-next-line no-invalid-this
      func.apply(this, args)
    }, delay)
  } as T
}

export const getEventsKy = debounce(
  async (
    navigate: any,
    page: number,
    value: string,
    typeFilter: number[],
    dateFilter: Array<string | null>,
    myEventsFilter: boolean
  ) => {
    isLoaderEvents()

    const res = await ky.post(
      'event/fetch_events',
      value !== ''
        ? {
            json: {
              search: value,
              limit: 10,
              page: page,
              withPast: true,
              categoryID: typeFilter,
              dateFrom: dateFilter[0],
              dateTo: dateFilter[1],
              userUUID: myEventsFilter ? localStorage.getItem('userID') : null,
            },
          }
        : {
            json: {
              page: page,
              limit: 10,
              withPast: true,
              categoryID: typeFilter,
              dateFrom: dateFilter[0],
              dateTo: dateFilter[1],
              userUUID: myEventsFilter ? localStorage.getItem('userID') : null,
            },
          }
    )

    if (res.status === 400) {
      setAlertState('fetch_events 400')
      isLoaderEvents()
      return
    }

    if (res.status === 401) {
      removeItemAndExit()
      navigate('/login')
      isLoaderEvents()
      return
    }

    if (res.status === 403) {
      setAlertState('недостаточно прав')
      isLoaderEvents()
      return
    }

    if (res.status === 412) {
      removeItemAndExit()
      navigate('/login')
      isLoaderEvents()
      return
    }

    if (res.status === 500) {
      setAlertState('fetch_events 500')
      isLoaderEvents()
      return
    }

    if (res.status === 200) {
      const data: any = await res.json()
      setEvents(data.result)
      setTotal(data.paginator.pageAvailable)
      isLoaderEvents()
      return
    }
  },
  1000
)

export const deleteEvent = async (
  navigate: any,
  eventID: number,
  typeFilter: number[],
  dateFilter: Array<string | null>,
  myEventsFilter: boolean
) => {
  isLoaderEvents()
  const res = await ky.post('event/delete_event', {
    json: {
      eventID: eventID,
    },
  })

  if (res.status === 400) {
    setAlertState('delete_event 400')
    isLoaderEvents()
    return
  }

  if (res.status === 401) {
    removeItemAndExit()
    navigate('/login')
    isLoaderEvents()
    return
  }

  if (res.status === 403) {
    setAlertState('недостаточно прав')
    isLoaderEvents()
    return
  }

  if (res.status === 412) {
    removeItemAndExit()
    navigate('/login')
    isLoaderEvents()
    return
  }

  if (res.status === 500) {
    setAlertState('delete_event 500')
    isLoaderEvents()
    return
  }

  if (res.status === 200) {
    isLoaderEvents()
    getEventsKy(navigate, 1, '', typeFilter, dateFilter, myEventsFilter)
    return
  }
}

export const mockEvent: Event = {
  bitrixID: 0,
  categoryID: 0,
  categoryName: '',
  createdAt: '',
  date: [
    {
      dateFrom: '01.01.2024 00:00:00',
      dateTo: '',
    },
  ],
  eventID: 1,
  eventName: '',
  fromBitrix: true,
  inviteToken: '',
  limits: {
    mentors: 0,
    students: 0,
    volunteers: 0,
  },
  location: {
    addressName: '',
    description: '',
    latitude: '',
    longitude: '',
  },
  properties: {
    chat: '',
    other: '',
    speakers: [''],
  },
  registeredCount: {
    mentors: 0,
    students: 0,
    volunteers: 0,
  },
  comingCount: {
    mentors: 0,
    students: 0,
    volunteers: 0,
  },
  typeID: 0,
  typeName: '',
  visible: true,
}

export const returnCorrectEvent = (state: Event, event: Event): Event => {
  return {
    bitrixID: nullСheck(event.bitrixID, state.bitrixID),
    categoryID: nullСheck(event.categoryID, state.categoryID),
    categoryName: nullСheck(event.categoryName, state.categoryName),
    createdAt: nullСheck(event.createdAt, state.createdAt),
    date: event.date
      ? [
          {
            dateFrom: nullСheck(event.date[0].dateFrom, state.date[0].dateFrom),
            dateTo: nullСheck(event.date[0].dateTo, state.date[0].dateTo),
          },
        ]
      : state.date,
    eventID: nullСheck(event.eventID, state.eventID),
    eventName: nullСheck(event.eventName, state.eventName),
    fromBitrix: event.fromBitrix ? true : false,
    inviteToken: nullСheck(event.inviteToken, state.inviteToken),
    limits: event.limits
      ? {
          mentors: nullСheck(event.limits.mentors, state.limits.mentors),
          students: nullСheck(event.limits.students, state.limits.students),
          volunteers: nullСheck(event.limits.volunteers, state.limits.volunteers),
        }
      : state.limits,
    location: event.location
      ? {
          addressName: nullСheck(event.location.addressName, state.location.addressName),
          description: nullСheck(event.location.description, state.location.description),
          latitude: nullСheck(event.location.latitude, state.location.latitude),
          longitude: nullСheck(event.location.longitude, state.location.longitude),
        }
      : state.location,
    properties: event.properties
      ? {
          chat: nullСheck(event.properties.chat, state.properties.chat),
          other: nullСheck(event.properties.other, state.properties.other),
          speakers: nullСheck(event.properties.speakers, state.properties.speakers),
        }
      : state.properties,
    registeredCount: event.registeredCount
      ? {
          mentors: nullСheck(
            event.registeredCount.mentors,
            state.registeredCount.mentors
          ),
          students: nullСheck(
            event.registeredCount.students,
            state.registeredCount.students
          ),
          volunteers: nullСheck(
            event.registeredCount.volunteers,
            state.registeredCount.volunteers
          ),
        }
      : state.registeredCount,
    comingCount: event.comingCount
      ? {
          mentors: nullСheck(event.comingCount.mentors, state.comingCount.mentors),
          students: nullСheck(event.comingCount.students, state.comingCount.students),
          volunteers: nullСheck(
            event.comingCount.volunteers,
            state.comingCount.volunteers
          ),
        }
      : state.comingCount,
    typeID: nullСheck(event.typeID, state.typeID),
    typeName: nullСheck(event.typeName, state.typeName),
    visible: event.visible ? true : false,
  }
}
