import {
  isLoader,
  isLoaderEvents,
  isLoaderUsersRegistered,
  setAlertState,
  setCurrentInviteToken,
} from 'entities'
import { getCurrentEventKy } from 'shared/api/events'
import ky from 'shared/api/ky'
import { nullСheck } from 'shared/lib/null-check'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
import { setUsersRegistered } from './model'
import { RegitredUser } from './types'

export const isEditEvents = async (navigate: any, event: any) => {
  isLoaderEvents()
  const res = await ky.post('event/modify_event', {
    json: {
      name: event.name,
      eventID: event.eventID,
      categoryID: event.typeEvent,
      date: {
        dateFrom: event.date,
        dateTo: event.date,
      },
      fromBitrix: false,
      limits: {
        mentors: 10,
        students: event.numberUsers,
        volunteers: 10,
      },
      location: {
        addressName: event.addressEvent,
        description: event.howToGet,
        latitude: null,
        longitude: null,
      },
      properties: {
        chat: null,
        other: event.eventDescription,
        speakers: null,
      },
      typeID: event.typeEvent,
      visible: true,
    },
  })

  if (res.status === 400) {
    setAlertState('modify_event 400')
    isLoaderEvents()
    return
  }

  if (res.status === 401) {
    navigate('/login')
    isLoaderEvents()
    return
  }

  if (res.status === 403) {
    setAlertState('недостаточно прав')
    isLoaderEvents()
    return
  }

  if (res.status === 412) {
    navigate('/login')
    isLoaderEvents()
    return
  }

  if (res.status === 500) {
    setAlertState('modify_event 500')
    isLoaderEvents()
    return
  }

  if (res.status === 200) {
    isLoaderEvents()
    getCurrentEventKy(navigate, event.date, event.eventID)
    return
  }
}

export const getTokenEvents = async (navigate: any, eventID: any) => {
  isLoader()
  const res = await ky.post('invite/create_invite_token', {
    // json: {
    //   eventID: eventID,
    //   numberTokens: 1,
    // },
    json: {
      count: 1,
      entityID: eventID,
      expirationTime: null,
      tokenLength: 8,
      inviteTokens: null,
      tokenAnotherRole: 6,
      tokenRole: 1,
    },
  })

  if (res.status === 400) {
    setAlertState('create_invite_token 400')
    isLoader()
    return
  }

  if (res.status === 401) {
    removeItemAndExit()
    navigate('/login')
    isLoader()
    return
  }

  if (res.status === 403) {
    setAlertState('недостаточно прав')
    isLoader()
    return
  }

  if (res.status === 412) {
    removeItemAndExit()
    navigate('/login')
    isLoader()
    return
  }

  if (res.status === 500) {
    setAlertState('InviteGeneration 500')
    isLoader()
    return
  }

  if (res.status === 200) {
    const data: any = await res.json()
    setCurrentInviteToken(data.inviteTokens[0])
    isLoader()
    return
  }
}

export const formatEventData = [
  {
    typeID: 1,
    typeName: 'Оффлайн',
    typeDescription: 'фывфыв',
  },
  {
    typeID: 2,
    typeName: 'Онлайн',
    typeDescription: 'фыв',
  },
  {
    typeID: 3,
    typeName: 'Оффлайн/Онлайн',
    typeDescription: 'фывфывфыв',
  },
]

export const categoryEvent = [
  {
    categoryID: 1,
    categoryName: 'Обычные события',
    categoryDescription: 'фывфыв',
  },
  {
    categoryID: 2,
    categoryName: 'Наставничество',
    categoryDescription: 'фывыфв',
  },
  {
    categoryID: 4,
    categoryName: 'Игры',
    categoryDescription: 'игры',
  },
  {
    categoryID: 3,
    categoryName: 'Школа',
    categoryDescription: 'фывыфв',
  },
]

export const formatEventName = formatEventData.map((item) => {
  return item.typeName
})

export const categoryEventName = categoryEvent.map((item) => {
  return item.categoryName
})

export function getformatEvent(formatEvent: string) {
  const foundType = formatEventData.find((format) => format.typeName === formatEvent)
  return foundType ? foundType.typeID : null
}

export function getCategoryEvent(typeEvent: string) {
  const foundType = categoryEvent.find((category) => category.categoryName === typeEvent)
  return foundType ? foundType.categoryID : null
}

export const mockRegistredUser: RegitredUser = {
  userInformation: {
    userID: '',
    firstName: '',
    lastName: '',
    middleName: '',
    photoURL: '',
    bitrixID: 0,
    background: {
      competence: '',
      employment: '',
    },
    bans: '',
    isBanned: false,
    location: {
      addressName: '',
      cityName: '',
      districtName: '',
      countryName: '',
    },
    userRoles: [
      {
        info: {
          salary: 0,
          mentor_level: 0,
        },
        permission: 4,
        role: 'Студент',
      },
    ],
    contactInfo: [
      {
        contact: '',
        contactName: '',
        contactType: 0,
        isVerified: true,
      },
    ],
    birthday: '',
    oldUserID: 0,
    aboutMe: '',
    userEventID: 0,
    userEventName: '',
    enableNotification: true,
    photo: {
      coord: {
        height: 0,
        width: 0,
        x: 0,
        y: 0,
      },
      photoBase64: '',
    },
    createdAt: '',
    updatedAt: '',
    useTOTP: true,
  },
  userRegistration: [
    {
      userID: '',
      date: '01.01.2024 00:00:00',
      statusID: 1,
      statusName: '',
      reason: '',
      registeredAt: 0,
      eventID: 0,
      createdAt: '',
    },
  ],
}

export const returnCorrectREgistredUser = (
  state: RegitredUser,
  user: RegitredUser
): RegitredUser => {
  return {
    userInformation: {
      userID: nullСheck(user.userInformation.userID, state.userInformation.userID),
      firstName: nullСheck(
        user.userInformation.firstName,
        state.userInformation.firstName
      ),
      lastName: nullСheck(user.userInformation.lastName, state.userInformation.lastName),
      middleName: nullСheck(
        user.userInformation.middleName,
        state.userInformation.middleName
      ),
      photoURL: nullСheck(user.userInformation.photoURL, state.userInformation.photoURL),
      bitrixID: nullСheck(user.userInformation.bitrixID, state.userInformation.bitrixID),
      background: user.userInformation.background
        ? {
            competence: nullСheck(
              user.userInformation.background.competence,
              state.userInformation.background.competence
            ),
            employment: nullСheck(
              user.userInformation.background.employment,
              state.userInformation.background.employment
            ),
          }
        : state.userInformation.background,
      bans: nullСheck(user.userInformation.bans, state.userInformation.bans),
      isBanned: false,
      location: user.userInformation.location
        ? {
            addressName: nullСheck(
              user.userInformation.location.addressName,
              state.userInformation.location.addressName
            ),
            cityName: nullСheck(
              user.userInformation.location.cityName,
              state.userInformation.location.cityName
            ),
            districtName: nullСheck(
              user.userInformation.location.districtName,
              state.userInformation.location.districtName
            ),
            countryName: nullСheck(
              user.userInformation.location.countryName,
              state.userInformation.location.countryName
            ),
          }
        : state.userInformation.location,
      userRoles: nullСheck(
        user.userInformation.userRoles,
        state.userInformation.userRoles
      ),
      contactInfo: user.userInformation.contactInfo
        ? user.userInformation.contactInfo
        : state.userInformation.contactInfo,
      birthday: nullСheck(user.userInformation.birthday, state.userInformation.birthday),
      oldUserID: nullСheck(
        user.userInformation.oldUserID,
        state.userInformation.oldUserID
      ),
      aboutMe: nullСheck(user.userInformation.aboutMe, state.userInformation.aboutMe),
      userEventID: nullСheck(
        user.userInformation.userEventID,
        state.userInformation.userEventID
      ),
      userEventName: nullСheck(
        user.userInformation.userEventName,
        state.userInformation.userEventName
      ),
      enableNotification: user.userInformation.enableNotification ? true : false,
      photo: user.userInformation.photo
        ? {
            coord: user.userInformation.photo.coord
              ? {
                  height: nullСheck(
                    user.userInformation.photo.coord.height,
                    state.userInformation.photo.coord.height
                  ),
                  width: nullСheck(
                    user.userInformation.photo.coord.width,
                    state.userInformation.photo.coord.width
                  ),
                  x: nullСheck(
                    user.userInformation.photo.coord.x,
                    state.userInformation.photo.coord.x
                  ),
                  y: nullСheck(
                    user.userInformation.photo.coord.y,
                    state.userInformation.photo.coord.y
                  ),
                }
              : state.userInformation.photo.coord,
            photoBase64: nullСheck(
              user.userInformation.photo.photoBase64,
              state.userInformation.photo.photoBase64
            ),
          }
        : state.userInformation.photo,
      createdAt: nullСheck(
        user.userInformation.createdAt,
        state.userInformation.createdAt
      ),
      updatedAt: nullСheck(
        user.userInformation.updatedAt,
        state.userInformation.updatedAt
      ),
      useTOTP: user.userInformation.useTOTP ? true : false,
    },
    userRegistration: [
      {
        userID: nullСheck(
          user.userRegistration[0].userID,
          state.userRegistration[0].userID
        ),
        date: nullСheck(user.userRegistration[0].date, state.userRegistration[0].date),
        statusID: nullСheck(
          user.userRegistration[0].statusID,
          state.userRegistration[0].statusID
        ),
        statusName: nullСheck(
          user.userRegistration[0].statusName,
          state.userRegistration[0].statusName
        ),
        reason: nullСheck(
          user.userRegistration[0].reason,
          state.userRegistration[0].reason
        ),
        registeredAt: nullСheck(
          user.userRegistration[0].registeredAt,
          state.userRegistration[0].registeredAt
        ),
        eventID: nullСheck(
          user.userRegistration[0].eventID,
          state.userRegistration[0].eventID
        ),
        createdAt: nullСheck(
          user.userRegistration[0].createdAt,
          state.userRegistration[0].createdAt
        ),
      },
    ],
  }
}

export const getRegistredUsersKy = async (
  navigate: any,
  date: string,
  eventID: number,
  registeredAt: number | null
) => {
  isLoaderUsersRegistered()
  const res = await ky.post('event/fetch_registered_on_event', {
    json: {
      filters: {
        eventID: eventID,
        registeredAs: registeredAt,
      },
      pagination: {
        limit: 100,
        page: 1,
      },
    },
  })

  if (res.status === 400) {
    isLoaderUsersRegistered()
    setAlertState('fetch_registered_on_event 400')
    return
  }

  if (res.status === 401) {
    isLoaderUsersRegistered()
    removeItemAndExit()
    navigate('/login')
    return
  }

  if (res.status === 403) {
    isLoaderUsersRegistered()
    setAlertState('недостаточно прав')
    return
  }

  if (res.status === 412) {
    isLoaderUsersRegistered()
    removeItemAndExit()
    navigate('/login')
    return
  }

  if (res.status === 500) {
    isLoaderUsersRegistered()
    setAlertState('fetch_registered_on_event 500')
    return
  }

  if (res.status === 200) {
    const data: any = await res.json()
    setUsersRegistered(data.data)
    isLoaderUsersRegistered()
    return
  }
}
