import { Button, Checkbox, Flex, Menu, Select, Switch } from '@mantine/core'
// eslint-disable-next-line import/no-internal-modules
import { useStore } from 'effector-react'
// eslint-disable-next-line import/no-internal-modules
import { deleteTypeArr, setTypeArr } from './model/events'
// eslint-disable-next-line import/no-internal-modules
import { $dateFilter, setDateFilter, setMyEventsFilter, setTypeFilter } from 'entities'
// eslint-disable-next-line import/no-internal-modules
// eslint-disable-next-line import/no-internal-modules
// eslint-disable-next-line import/no-internal-modules
import { CustomDateRadiusPicker } from '../custom-date-radius-picker'
// eslint-disable-next-line import/no-internal-modules
import { $typeArr } from './model/store'

const categoryEvent = [
  {
    categoryID: 1,
    categoryName: 'Обычные события',
    categoryDescription: 'фывфыв',
  },
  {
    categoryID: 2,
    categoryName: 'Наставничество',
    categoryDescription: 'фывыфв',
  },
  {
    categoryID: 3,
    categoryName: 'Школа',
    categoryDescription: 'игры',
  },
  {
    categoryID: 4,
    categoryName: 'Игры',
    categoryDescription: 'фывыфв',
  },
]

const FilterEvent = () => {
  const date = useStore($dateFilter)
  const storeArr = useStore($typeArr)
  return (
    <Flex align='center' gap={10}>
      <Menu shadow='md' width={200}>
        <Menu.Target>
          <Select
            placeholder='Тип события'
            style={{
              borderRadius: '8px',
            }}
          />
        </Menu.Target>
        <Menu.Dropdown>
          {categoryEvent.map((item) => {
            return (
              <FilterItem
                key={item.categoryID}
                id={item.categoryID}
                text={item.categoryName}
              />
            )
          })}
          <Menu.Item>
            <Button onClick={() => setTypeFilter(storeArr)} color='black'>
              Применить
            </Button>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      {/* <CustomDatePicker
        onChange={(event) => {
          // setDateFilter(event ? formatDate(String(event)) : event)
        }}
      /> */}
      <CustomDateRadiusPicker
        onChange={(start, end) => {
          setDateFilter([
            start ? formatDate(String(start)) : start,
            end ? formatDate(String(end)) : end,
          ])
        }}
      />
      <Switch
        onChange={(e) => {
          setMyEventsFilter(e.target.checked)
        }}
        label='Мои события'
      />
    </Flex>
  )
}

const FilterItem = ({ text, id }: any) => {
  const storeArr = useStore($typeArr)

  return (
    <Checkbox
      mb={10}
      onChange={(event) => {
        if (event.currentTarget.checked === true) {
          setTypeArr(id)
          return
        } else {
          deleteTypeArr(id)
          return
        }
      }}
      checked={storeArr.includes(id)}
      labelPosition='right'
      label={`${text}`}
      style={{
        cursor: 'pointer',
      }}
    />
  )
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString)

  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const seconds = date.getSeconds().toString().padStart(2, '0')

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
}

export default FilterEvent
