import NotFound from 'pages/404'
import EventsPage from 'pages/events'
import LoginPage from 'pages/login'
import UsersPage from 'pages/users'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            localStorage.getItem('apiKey') &&
            localStorage.getItem('2fa') &&
            localStorage.getItem('invite') ? (
              <Navigate to='/users' />
            ) : (
              <Navigate to='/login' />
            )
          }
        />
        <Route
          path='/main'
          element={
            localStorage.getItem('apiKey') &&
            localStorage.getItem('2fa') &&
            localStorage.getItem('invite') ? (
              <Navigate to='/users' />
            ) : (
              <Navigate to='/login' />
            )
          }
        />
        <Route
          path='/login'
          element={
            localStorage.getItem('apiKey') &&
            localStorage.getItem('2fa') &&
            localStorage.getItem('invite') ? (
              <Navigate to='/users' />
            ) : (
              <LoginPage />
            )
          }
        />
        <Route
          path='/users'
          element={
            localStorage.getItem('apiKey') &&
            localStorage.getItem('2fa') &&
            localStorage.getItem('invite') ? (
              <UsersPage />
            ) : (
              <Navigate to='/login' />
            )
          }
        />
        <Route
          path='/events'
          element={
            localStorage.getItem('apiKey') &&
            localStorage.getItem('2fa') &&
            localStorage.getItem('invite') ? (
              <EventsPage />
            ) : (
              <Navigate to='/login' />
            )
          }
        />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
