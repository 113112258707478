/* eslint-disable functional/no-conditional-statements */
import { isLoaderEvents, setAlertState, setCurrentEvent } from 'entities'
// eslint-disable-next-line boundaries/element-types
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
// eslint-disable-next-line boundaries/element-types
import ky from './ky'
// eslint-disable-next-line boundaries/element-types
import { getEventsKy } from 'widgets/events-table'

export const iscCreateEventsKy = async (
  navigate: any,
  event: any,
  close: () => void,
  page: number,
  value: string,
  typeFilter: number[],
  dateFilter: Array<string | null>,
  myEventsFilter: boolean
) => {
  // eslint-disable-next-line functional/no-loop-statements
  for (const item of event.date) {
    isLoaderEvents()
    const res = await ky.post('event/create_event', {
      json: {
        name: event.name,
        categoryID: event.typeEvent,
        date: {
          dateFrom: item.dateStart,
          dateTo: item.dateEnd,
        },
        fromBitrix: false,
        limits: {
          mentors: 10,
          students: Number(event.numberUsers),
          volunteers: 10,
        },
        location: {
          addressName: event.addressEvent,
          description: event.howToGet,
          latitude: null,
          longitude: null,
        },
        properties: {
          chat: null,
          other: event.eventDescription,
          speakers: null,
        },
        typeID: event.formatEvent,
        visible: true,
      },
    })

    if (res.status === 400) {
      isLoaderEvents()
      setAlertState('create_event 400')
    }

    if (res.status === 401) {
      removeItemAndExit()
      navigate('/login')
      isLoaderEvents()
    }

    if (res.status === 403) {
      setAlertState('недостаточно прав')
      isLoaderEvents()
    }

    if (res.status === 412) {
      removeItemAndExit()
      navigate('/login')
      isLoaderEvents()
    }

    if (res.status === 500) {
      setAlertState('create_event 500')
      isLoaderEvents()
    }

    if (res.status === 200) {
      isLoaderEvents()
      close()
      getEventsKy(navigate, page, value, typeFilter, dateFilter, myEventsFilter)
    }
  }
}

export const getCurrentEventKy = async (
  navigate: any,
  dateFrom: string,
  eventID: number
) => {
  isLoaderEvents()
  const res = await ky.post('event/get_event', {
    json: {
      dateFrom: dateFrom,
      eventID: eventID,
    },
  })

  if (res.status === 400) {
    setAlertState('get_event 400')
    isLoaderEvents()
    return
  }

  if (res.status === 401) {
    removeItemAndExit()
    navigate('/login')
    isLoaderEvents()
    return
  }

  if (res.status === 403) {
    setAlertState('недостаточно прав')
    isLoaderEvents()
    return
  }

  if (res.status === 412) {
    removeItemAndExit()
    navigate('/login')
    isLoaderEvents()
    return
  }

  if (res.status === 500) {
    setAlertState('get_event 500')
    isLoaderEvents()
    return
  }

  if (res.status === 200) {
    const data: any = await res.json()
    setCurrentEvent(data)
    isLoaderEvents()
    return
  }
}
