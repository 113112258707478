import styled from '@emotion/styled'
import { Text } from '@mantine/core'
import { CustomLinkImageProps } from './types'

export const CustomLinkImage = ({ icon, text }: CustomLinkImageProps) => {
  return (
    <MyButton>
      {icon}
      <Text ml={5} fz={16} fw={400}>
        {text}
      </Text>
    </MyButton>
  )
}

export const MyButton = styled.button`
  width: 100%;
  height: 56px;
  display: flex;
  padding: 16px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
`
