import {
  isLoaderEvents,
  setAlertState,
  setCurrentEvent,
  setCurrentInviteToken,
} from 'entities'
import ky from 'shared/api/ky'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
import { Role } from 'shared/lib/shared-types'

export function getCanCreateInviteUsers(users: Role[]) {
  return users.filter((user) => user.canCreateInvite)
}

export const getEventsInviteKy = async (
  navigate: any,
  value: string,
  setButtonsDisabled: any
) => {
  const res = await ky.post('event/fetch_events', {
    json: {
      search: value,
      limit: 10,
      page: 1,
    },
  })

  if (res.status === 400) {
    setAlertState(' fetch_events 400')
    return
  }

  if (res.status === 401) {
    removeItemAndExit()
    navigate('/login')
    return
  }

  if (res.status === 403) {
    setAlertState('недостаточно прав')
    return
  }

  if (res.status === 412) {
    removeItemAndExit()
    navigate('/login')
    return
  }

  if (res.status === 500) {
    setAlertState(' fetch_events 500')
    return
  }

  if (res.status === 200) {
    const data: any = await res.json()
    if (data.result.length === 1) {
      setCurrentEvent(data.result[0])
      setButtonsDisabled(false)
      return
    }

    return
  }
}

export const getTokenKy = async (navigate: any, id: number) => {
  isLoaderEvents()

  const res = await ky.post('invite/create_invite_token', {
    // json: {
    //   roleID: id,
    //   numberTokens: 1,
    // },
    json: {
      count: 1,
      entityID: 1,
      expirationTime: null,
      inviteTokens: null,
      tokenAnotherRole: id,
      tokenLength: 8,
      tokenRole: 0,
    },
  })

  if (res.status === 400) {
    setAlertState('create_role_token 400')
    isLoaderEvents()
    return
  }

  if (res.status === 401) {
    removeItemAndExit()
    navigate('/login')
    isLoaderEvents()
    return
  }

  if (res.status === 403) {
    setAlertState('недостаточно прав')
    isLoaderEvents()
    return
  }

  if (res.status === 412) {
    removeItemAndExit()
    navigate('/login')
    isLoaderEvents()
    return
  }

  if (res.status === 500) {
    setAlertState('InviteGeneration 500')
    isLoaderEvents()
    return
  }

  if (res.status === 200) {
    const data: any = await res.json()
    setCurrentInviteToken(data.inviteTokens[0])
    isLoaderEvents()
    return
  }
}
