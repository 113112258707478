import { createEvent } from 'effector'

interface DateForm {
  id: number
  dateStart: Date | null
  dateEnd: Date | null
}

interface DateItem {
  id: number
  date: Date | null
}

export const setCategoryEvent = createEvent<any[]>()
export const setDateStart = createEvent<DateItem>()
export const setDateEnd = createEvent<DateItem>()
export const setNewDateFields = createEvent<DateForm>()
export const removeNewDateFields = createEvent<number>()
