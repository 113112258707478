import { format, parseISO } from 'date-fns'
import React, { useState } from 'react'
import './custom-date-radius-picker.css'

interface DatePickerProps {
  label?: string
  initialStartValue?: Date
  initialEndValue?: Date
  onChange?: (startValue: Date | null, endValue: Date | null) => void
  w?: number
}

export const CustomDateRadiusPicker: React.FC<DatePickerProps> = ({
  w,
  label,
  initialStartValue,
  initialEndValue,
  onChange,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(initialStartValue || null)
  const [endDate, setEndDate] = useState<Date | null>(initialEndValue || null)

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = event.target.value ? parseISO(event.target.value) : null
    setStartDate(newStartDate)
    handleDateChange(newStartDate, endDate)
  }

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = event.target.value ? parseISO(event.target.value) : null
    setEndDate(newEndDate)
    handleDateChange(startDate, newEndDate)
  }

  const handleDateChange = (newStartDate: Date | null, newEndDate: Date | null) => {
    // eslint-disable-next-line functional/no-conditional-statements
    if (onChange) {
      onChange(newStartDate, newEndDate)
    }
  }

  return (
    <div className='date-picker'>
      {label && <label className='date-picker-label'>{label}</label>}
      <div
        style={{
          width: `${w}px`,
        }}
        className='date-picker-container'
      >
        <input
          type='date'
          className='date-picker-input'
          value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
          onChange={handleStartDateChange}
        />
        <span>-</span>
        <input
          type='date'
          className='date-picker-input'
          value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
          onChange={handleEndDateChange}
        />
      </div>
    </div>
  )
}
