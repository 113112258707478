import { Button, Flex, Loader, PasswordInput, Text, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useStore } from 'effector-react'
import {
  $loaderStore,
  $userStore,
  isLoader,
  newApiKey,
  newRole,
  newTOTP,
  setAlertState,
  setFirstName,
  setLastName,
  setPhoto,
  setUserID,
} from 'entities'
import ky from 'shared/api/ky'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
import { nextStep } from '../model'

export const FormPassword = () => {
  const loaderStore = useStore($loaderStore)
  const userStore = useStore($userStore)

  const form = useForm({
    initialValues: {
      password: '',
    },
    validateInputOnBlur: true,
    validate: {
      password: (value) => (/^.+$/.test(value) ? false : 'false'),
    },
  })

  const handleSubmit = async (values: string) => {
    const res = await ky.post('auth/immediate_sign_in', {
      json: {
        contact: userStore.emailHash,
        password: values,
      },
    })

    if (res.status === 400) {
      setAlertState('immediate_sign_in 400')
      isLoader()
      alert('400')
      return
    }

    if (res.status === 401) {
      removeItemAndExit()
      setAlertState('immediate_sign_in 401')
      isLoader()
      alert('400')
      return
    }

    if (res.status === 412) {
      removeItemAndExit()
      setAlertState('immediate_sign_in 412')
      isLoader()
      alert('400')
      return
    }

    if (res.status === 500) {
      isLoader()
      form.setErrors({ password: 'Неправильный пароль' })
      return
    }

    if (res.status === 200) {
      isLoader()
      const data: any = await res.json()
      // eslint-disable-next-line functional/no-let
      let roleBoolean = false
      // eslint-disable-next-line functional/no-loop-statements
      for (const item of data.userInfo.userRoles) {
        // eslint-disable-next-line functional/no-conditional-statements
        if (item.permission === 1 || item.permission === 7 || item.permission === 8) {
          roleBoolean = true
        }
      }
      newApiKey(data.apiKey)
      setUserID(data.userInfo.userID)
      setFirstName(data.userInfo.firstName)
      setLastName(data.userInfo.lastName)
      setPhoto(data.userInfo.photo ? data.userInfo.photo.photoBase64 : '')
      localStorage.setItem(
        'photo',
        data.userInfo.photo ? data.userInfo.photo.photoBase64 : ''
      )
      localStorage.setItem('apiKey', data.apiKey)
      localStorage.setItem('userID', data.userInfo.userID)
      localStorage.setItem('firstName', data.userInfo.firstName)
      localStorage.setItem('lastName', data.userInfo.lastName)
      newRole(roleBoolean)
      newTOTP(data.userInfo.useTOTP)
      if (roleBoolean) {
        localStorage.setItem('invite', 'true')
        nextStep('Form2FA')
        return
      } else {
        nextStep('FormInviteToken')
        return
      }
      return
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        isLoader()
        handleSubmit(values.password)
      })}
    >
      <Flex w='80%' gap={20} direction='column'>
        <Title fz={42} fw={600}>
          Введите пароль
        </Title>
        <Text c='#8F8F8F' fz={18} fw={400}>
          Укажите пароль, привязанный к электронной почте{' '}
          <Text display='inline' fw={600} c='black'>
            {userStore.email}
          </Text>
        </Text>

        <PasswordInput
          withAsterisk
          key={form.key('password')}
          {...form.getInputProps('password')}
          size='lg'
          radius={12}
          placeholder='Пароль'
        />

        <Text fz={18} fw={400} c='#8F8F8F'>
          Забыли пароль? Обращайтесь в техподдержку
        </Text>

        <Button
          disabled={!form.isValid()}
          type='submit'
          size='lg'
          radius={12}
          variant='filled'
          color='black'
        >
          {loaderStore ? <Loader color='white' /> : 'Продолжить'}
        </Button>
      </Flex>
    </form>
  )
}
