import { isLoader, setAlertState, setPhoto } from 'entities'
import ky from 'shared/api/ky'
import { getCurrentUserKy, getUsersKy } from 'shared/api/users'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
import { MentorsCategoty } from 'shared/lib/shared-types'

export const nameTableUserInfo = [
  'ID',
  'Дата рождения',
  'Местоположение',
  'Имя пользователя',
  'Телефон',
  'Почта',
  'Телеграм',
  'О себе',
]

export const convertToBase64 = (
  file: any,
  navigate: any,
  apiKey: string,
  userID: string,
  myUserID: string
) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  // eslint-disable-next-line functional/immutable-data
  reader.onload = () => {
    const result: string = reader.result ? (reader.result as string) : ('' as string)
    const photoBase64 = result.replace(`data:${file.type};base64,`, '')
    updateAvatarKy(navigate, photoBase64, userID, myUserID)
  }
  // eslint-disable-next-line functional/immutable-data
  reader.onerror = (error) => {
    setAlertState('не удалось сменить аватар')
  }
}

export const returnCategory = (
  categoryID: number,
  mentorsCategory: MentorsCategoty[]
): string => {
  const category = mentorsCategory.find((category) => category.categoryID === categoryID)
  return category ? category.categoryName : 'support'
}

export const deleteRoleKy = async (
  navigate: any,
  permission: number,
  id: string,
  setRole: boolean
) => {
  const res = await ky.post('users/change_role', {
    json: {
      setRole: setRole,
      roleID: permission,
      userInformation: '1',
      userID: id,
    },
  })

  if (res.status === 400) {
    setAlertState('change_role 400')
    return
  }

  if (res.status === 401) {
    removeItemAndExit()
    navigate('/login')
    return
  }

  if (res.status === 403) {
    setAlertState('недостаточно прав')
    return
  }

  if (res.status === 412) {
    removeItemAndExit()
    navigate('/login')
    return
  }

  if (res.status === 500) {
    setAlertState('change_role 500')
    return
  }

  if (res.status === 200) {
    const data = await res.json()
    return
  }
}

export const updateAvatarKy = async (
  navigate: any,
  photoBase64: any,
  userID: string,
  myUserID: string
) => {
  isLoader()
  const res = await ky.post('users/change_user_photo', {
    json: {
      coord: null,
      photoBase64: photoBase64,
      userID: userID,
    },
  })

  if (res.status === 400) {
    isLoader()
    setAlertState('change_user_photo 400')
    return
  }

  if (res.status === 401) {
    isLoader()
    removeItemAndExit()
    navigate('/login')
    return
  }

  if (res.status === 403) {
    isLoader()
    setAlertState('недостаточно прав')
    return
  }

  if (res.status === 412) {
    isLoader()
    removeItemAndExit()
    navigate('/login')
    return
  }

  if (res.status === 500) {
    isLoader()
    setAlertState('change_user_photo 500')
    return
  }

  if (res.status === 200) {
    // eslint-disable-next-line functional/no-conditional-statements
    if (myUserID === userID) {
      setPhoto(photoBase64)
      localStorage.setItem('photo', photoBase64)
    }
    getCurrentUserKy(navigate, userID)
    await getUsersKy(navigate, 1, '')
    isLoader()
    return
  }
}

export const updateUsersKy = async (navigate: any, currentUser: any, user: any) => {
  isLoader()
  const res = await ky.post('users/change_user_data', {
    json: {
      aboutMe: user.aboutMe,
      background: {
        competence: user.competence,
        employment: user.employment,
      },
      birthday: user.birthday,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      locale: currentUser.locale,
      location: {
        addressName: user.location,
        cityName: currentUser.location.cityName,
        countryName: currentUser.location.countryName,
        districtName: currentUser.location.districtName,
      },
      middleName: currentUser.middleName,
      salaryLevel: user.salaryLevel,
      userID: currentUser.userID,
    },
  })

  if (res.status === 400) {
    isLoader()
    setAlertState('change_user_data 400')
    return
  }

  if (res.status === 401) {
    isLoader()
    removeItemAndExit()
    navigate('/login')
    return
  }

  if (res.status === 403) {
    isLoader()
    setAlertState('недостаточно прав')
    return
  }

  if (res.status === 412) {
    isLoader()
    removeItemAndExit()
    navigate('/login')
    return
  }

  if (res.status === 500) {
    isLoader()
    setAlertState('change_user_data 500')
    return
  }

  if (res.status === 200) {
    getCurrentUserKy(navigate, currentUser.userID)
    isLoader()
    return
  }
}
