import { useStore } from 'effector-react'
import { $myInfo } from 'entities'
import { useLocation } from 'react-router'
// eslint-disable-next-line boundaries/element-types
import DrawerEventsInfo from 'widgets/drawer-events-info'
// eslint-disable-next-line boundaries/element-types
import DrawerUserInfo from 'widgets/driwer-user-info'

export const DrawerInfo = ({ opened, close }: any) => {
  const { pathname } = useLocation()
  const myInfo = useStore($myInfo)

  return pathname === '/events' && !myInfo ? (
    <DrawerEventsInfo opened={opened} close={close} />
  ) : (
    <DrawerUserInfo opened={opened} close={close} />
  )
}
