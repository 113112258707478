import { ActionIcon, Button, Flex, Menu, Modal, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useStore } from 'effector-react'
import { $userStore, setAlertState } from 'entities'
import { $currentUser } from 'entities/drawer-user-info'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
// eslint-disable-next-line import/no-internal-modules
import ky from 'shared/api/ky'
// eslint-disable-next-line import/no-internal-modules
import { Dotted, PlusBlue } from 'shared/asset'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
import { deleteRoleKy } from '../utils'

export const HeaderDrawer = ({ roles }: any) => {
  const users = useStore($userStore)
  const navigate = useNavigate()
  const [allRoles, setAllRoles] = useState([
    {
      id: 6,
      name: 'Волонтер',
    },
  ])

  useEffect(() => {
    getRolesKy(navigate)
  }, [])

  const getRolesKy = async (navigate: any) => {
    const res = await ky.post('users/fetch_user_roles', {})

    if (res.status === 400) {
      setAlertState('fetch_user_roles 400')
      return
    }

    if (res.status === 401) {
      removeItemAndExit()
      navigate('/login')
      return
    }

    if (res.status === 403) {
      setAlertState('недостаточно прав')
      return
    }

    if (res.status === 412) {
      removeItemAndExit()
      navigate('/login')
      return
    }

    if (res.status === 500) {
      setAlertState('fetch_user_roles 500')
      return
    }

    if (res.status === 200) {
      const data: any = await res.json()
      setAllRoles(data)
      return
    }
  }

  return (
    <Flex
      p={24}
      align='center'
      justify='space-between'
      style={{
        borderBottom: '1px solid silver',
      }}
      w='100%'
    >
      <TagsRoleNew allRoles={allRoles} roles={roles} />
      <ActionIcon color='white' variant='filled'>
        <Dotted />
      </ActionIcon>
    </Flex>
  )
}

const TagsRoleNew = ({ roles, allRoles }: any) => {
  return (
    <Flex
      align='center'
      p='12px 16px 12px 16px'
      justify='space-between'
      bg='#F9F9F9'
      style={{
        borderRadius: '12px',
      }}
    >
      <Flex align='center' gap='12px'>
        {roles.map((item: any) => {
          return (
            <RoleItem
              key={item.permission}
              permission={item.permission}
              text={item.role}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

const PopoverMenu = ({ allRoles }: any) => {
  return (
    <Menu shadow='md' width={200}>
      <Menu.Target>
        <ActionIcon color='#F9F9F9'>
          <PlusBlue />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Выбирете роль</Menu.Label>
        {allRoles.map((role: any) => {
          return <MenuItem key={role.id} permission={role.id} name={role.name} />
        })}
      </Menu.Dropdown>
    </Menu>
  )
}

const MenuItem = ({ permission, name }: any) => {
  const users = useStore($userStore)
  const currentUser = useStore($currentUser)
  const navigate = useNavigate()

  return (
    <Menu.Item
      onClick={() => {
        deleteRoleKy(navigate, permission, currentUser.userID, true)
      }}
    >
      {name}
    </Menu.Item>
  )
}

const RoleItem = ({ text, permission }: any) => {
  const [opened, { open, close }] = useDisclosure(false)
  const users = useStore($userStore)
  const currentUser = useStore($currentUser)
  const navigate = useNavigate()
  return (
    <>
      <Flex
        align='center'
        style={{
          borderRadius: '36px',
        }}
        p='4px 8px 4px 8px'
        bg='#EEF6FF'
      >
        <Text fz={16} fw={400} c='#1681FF'>
          {text}
        </Text>
      </Flex>
      <Modal centered radius={24} size={718} opened={opened} onClose={close}>
        <Flex mb={20} p='0px 60px' gap={20} direction='column' w='100%' align='center'>
          <Text fz={32} fw={600}>
            {`Вы уверены, что хотите лишить пользователя прав ${text}`}{' '}
          </Text>
          <Button
            onClick={() => {
              close()
              deleteRoleKy(navigate, permission, currentUser.userID, false)
            }}
            color='#262626'
            w='100%'
          >
            да
          </Button>
          <Button onClick={close} color='#262626' w='100%'>
            нет
          </Button>
        </Flex>
      </Modal>
    </>
  )
}
