import { Flex, Modal, Text, Title } from '@mantine/core'
import { useStore } from 'effector-react'
import { Link } from 'react-router-dom'
// eslint-disable-next-line import/no-internal-modules
import {
  $userStore,
  newEmail,
  newEmailHash,
  newPhone,
  newPhoneHash,
  setAlertState,
} from 'entities'
import ky from 'shared/api/ky'
// eslint-disable-next-line import/no-internal-modules
import { CheckRight, Message, Password, Phone } from 'shared/asset'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'
import { $stepForm, nextStep } from '../model'
import {
  LinkModalAnotherLoginOptionProps,
  ModalAnotherLoginOptionProps,
} from './../index'

export const ModalAnotherLoginOption = ({
  opened,
  close,
  setTime,
  setIsTimerActive,
}: ModalAnotherLoginOptionProps) => {
  const userStore = useStore($userStore)
  const stepForm = useStore($stepForm)
  const step = stepForm[stepForm.length - 1]
  const dataLoginOption = [
    {
      id: 1,
      icon: <Phone />,
      link: 'FormСonfirmationСodePhoneVoice',
      title: 'Номер телефона',
      description: `На ваш номер ${userStore.phone} поступит звонок, введите код, который продиктует робот`,
      voice: true,
      display: step !== 'FormСonfirmationСodePhoneVoice',
    },
    {
      id: 2,
      icon: <Phone />,
      link: 'FormСonfirmationСodePhone',
      title: 'Номер телефона',
      description: `На ваш номер ${userStore.phone} поступит звонок, введите последние 4 цифры номера`,
      voice: false,
      display: step !== 'FormСonfirmationСodePhone',
    },
    {
      id: 3,
      icon: <Message />,
      link: 'FormСonfirmationСodeMessage',
      title: 'Электронная почта',
      description: `Получить код на e-mail ${userStore.email}`,
      voice: true,
      display: step !== 'FormСonfirmationСodeMessage',
    },
    {
      id: 4,
      icon: <Password />,
      link: 'FormPassword',
      title: 'Пароль',
      description: 'Ввести пароль от аккаунта',
      voice: false,
      display: step !== 'FormPassword',
    },
  ]
  return (
    <Modal centered radius={24} size={718} opened={opened} onClose={close}>
      <Flex mb={20} p='0px 60px' gap={40} direction='column' w='100%' align='center'>
        <Flex gap={20} direction='column'>
          <Title fz={32} fw={600}>
            Выберите способ подтверждения
          </Title>
          <Text c='#8F8F8F' fz={18} fw={400}>
            Для защиты аккаунта необходимо убедиться, что совершить вход в систему
            пытаетесь именно вы
          </Text>
        </Flex>
        <Flex direction='column' gap={30}>
          {dataLoginOption.map((item) => {
            return (
              <LinkModalAnotherLoginOption
                setTime={setTime}
                setIsTimerActive={setIsTimerActive}
                voice={item.voice}
                key={item.id}
                link={item.link}
                close={close}
                icon={item.icon}
                title={item.title}
                description={item.description}
                display={item.display}
              />
            )
          })}
        </Flex>
        <Link
          style={{
            textDecoration: 'none',
          }}
          to='https://reg.terra-leader.ru/help/'
          target='_blank'
        >
          <Text fz={18} fw={400} c='#8F8F8F'>
            Забыли пароль? Обращайтесь в техподдержку
          </Text>
        </Link>
      </Flex>
    </Modal>
  )
}

const onsubmitSignIn = async (values: string, link: string, voice: boolean) => {
  const res = await ky.post('auth/sign_in', {
    json: {
      contact: values,
      useVoice: voice,
    },
  })

  if (res.status === 400) {
    setAlertState('sign_in 400')
    return
  }

  if (res.status === 401) {
    removeItemAndExit()
    setAlertState('sign_in 401')
    return
  }

  if (res.status === 412) {
    removeItemAndExit()
    setAlertState('sign_in 412')
    return
  }

  if (res.status === 200) {
    const data: any = await res.json()
    const emailBool = data.contactInfoDecoded[0].name === 'email'
    newPhone(data.contactInfoDecoded[emailBool ? 1 : 0].contact)
    newPhoneHash(data.contactInfoDecoded[emailBool ? 1 : 0].contactHash)
    newEmail(data.contactInfoDecoded[emailBool ? 0 : 1].contact)
    newEmailHash(data.contactInfoDecoded[emailBool ? 0 : 1].contactHash)
    nextStep(link)
    return
  }
}

const LinkModalAnotherLoginOption = ({
  setTime,
  setIsTimerActive,
  icon,
  link,
  title,
  description,
  close,
  voice,
  display,
}: LinkModalAnotherLoginOptionProps) => {
  const userStore = useStore($userStore)
  return (
    <Flex
      style={{
        display: display ? 'flex' : 'none',
        cursor: 'pointer',
        ':hover': {
          backroundColor: 'red',
        },
      }}
      gap={16}
      onClick={() => {
        close()
        setTime(59)
        setIsTimerActive(true)
        if (link === 'FormСonfirmationСodePhone') {
          onsubmitSignIn(userStore.phoneHash, link, voice)
          return
        } else if (link === 'FormСonfirmationСodeMessage') {
          onsubmitSignIn(userStore.emailHash, link, voice)
          return
        } else if (link === 'FormСonfirmationСodePhoneVoice') {
          onsubmitSignIn(userStore.phoneHash, link, voice)
          return
        } else {
          nextStep(link)
          return
        }
      }}
    >
      <Flex pt={5}>{icon}</Flex>
      <Flex w={486} direction='column'>
        <Title fz={24} fw={500}>
          {title}
        </Title>
        <Text fz={18} fw={400}>
          {description}
        </Text>
      </Flex>
      <Flex align='center'>
        <CheckRight />
      </Flex>
    </Flex>
  )
}
