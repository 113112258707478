import { ActionIcon, Flex, Loader, Menu, Table } from '@mantine/core'
import { useStore } from 'effector-react'
// eslint-disable-next-line import/no-internal-modules
import { $loaderStore, $searchText, $userStore, setMyInfo } from 'entities'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { getCurrentUserKy, getUsersKy } from 'shared/api/users'
// eslint-disable-next-line import/no-internal-modules
import { Dotted, DottedMin, Trasher } from 'shared/asset'
import { getContactByType } from 'shared/lib/get-contact-by-type'
import { $users } from '../model'
import { UsersTableProps } from '../types'
import { deleteUser } from '../utils'
import { TableId } from './table-id'
import { TableRoles } from './table-roles'
import { TableUser } from './table-user'

const UsersTable = ({ open }: UsersTableProps) => {
  const users = useStore($users)
  const loader = useStore($loaderStore)
  const usersStore = useStore($userStore)
  const searchText = useStore($searchText)
  const navigate = useNavigate()

  useEffect(() => {
    getUsersKy(navigate, 1, searchText)
  }, [searchText])

  const rows = users.map((user) => (
    <Table.Tr
      style={{
        cursor: 'pointer',
        borderBottom: '1px solid #e0e0e0',
      }}
      key={user.userID}
    >
      <Table.Td
        onClick={() => {
          setMyInfo(false)
          open()
          getCurrentUserKy(navigate, user.userID)
        }}
      >
        <TableId id={user.userID} />
      </Table.Td>
      <Table.Td
        onClick={() => {
          setMyInfo(false)
          open()
          getCurrentUserKy(navigate, user.userID)
        }}
      >
        <TableUser firstName={user.firstName} lastName={user.lastName} img={user.photo} />
      </Table.Td>
      {/* <Table.Td>{getContactByType(2, user.contactInfo)}</Table.Td> */}
      <Table.Td
        onClick={() => {
          setMyInfo(false)
          open()
          getCurrentUserKy(navigate, user.userID)
        }}
      >
        <TableRoles roles={user.userRoles} />
      </Table.Td>
      <Table.Td
        onClick={() => {
          setMyInfo(false)
          open()
          getCurrentUserKy(navigate, user.userID)
        }}
      >
        {getContactByType(4, user.contactInfo)}
      </Table.Td>
      <Table.Td
        onClick={() => {
          setMyInfo(false)
          open()
          getCurrentUserKy(navigate, user.userID)
        }}
      >
        {getContactByType(3, user.contactInfo)}
      </Table.Td>
      <Table.Td
        onClick={() => {
          setMyInfo(false)
          open()
          getCurrentUserKy(navigate, user.userID)
        }}
      >
        {getContactByType(1, user.contactInfo)}
      </Table.Td>
      <Table.Td>
        <MenuInfoAndDelete userID={user.userID} apiKey={usersStore.apiKey} open={open} />
      </Table.Td>
    </Table.Tr>
  ))
  return (
    <>
      {loader ? (
        <Flex w='100%' h='100%' align='center' justify='center'>
          <Loader size={80} />
        </Flex>
      ) : (
        <Table
          style={{
            height: '54px',
          }}
        >
          <Table.Thead>
            <Table.Tr
              bg='#F9F9F9'
              style={{
                border: 'none',
              }}
            >
              <Table.Th>ID</Table.Th>
              <Table.Th>Пользователь</Table.Th>
              {/* <Table.Th>Имя пользователя</Table.Th> */}
              <Table.Th>Роль</Table.Th>
              <Table.Th>Телеграм</Table.Th>
              <Table.Th>Телефон</Table.Th>
              <Table.Th>Почта</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      )}
    </>
  )
}

const MenuInfoAndDelete = ({ userID, apiKey, open }: any) => {
  const navigate = useNavigate()
  return (
    <Menu shadow='md' width={200}>
      <Menu.Target>
        <ActionIcon color='white' variant='filled'>
          <Dotted />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          leftSection={<DottedMin />}
          onClick={() => {
            setMyInfo(false)
            open()
            getCurrentUserKy(navigate, userID)
          }}
        >
          Подробнее
        </Menu.Item>

        <Menu.Item
          c='#EB4D3D'
          leftSection={<Trasher />}
          onClick={() => {
            deleteUser(navigate, userID)
          }}
        >
          Удалить аккаунт
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export default UsersTable
