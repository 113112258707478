import { Flex, Pagination } from '@mantine/core'
import { useStore } from 'effector-react'
import { useLocation, useNavigate } from 'react-router'
// eslint-disable-next-line boundaries/element-types
import {
  $dateFilter,
  $myEventsFilter,
  $searchText,
  $total,
  $typeFilter,
  $userStore,
} from 'entities'
import { getUsersKy } from 'shared/api/users'
// eslint-disable-next-line boundaries/element-types
import { getEventsKy } from 'widgets/events-table'

export const PaginationButtons = () => {
  const total = useStore($total)
  const usersStore = useStore($userStore)
  const { pathname } = useLocation()
  const searchText = useStore($searchText)
  const typeFilter = useStore($typeFilter)
  const dateFilter = useStore($dateFilter)
  const myEventsFilter = useStore($myEventsFilter)
  const navigate = useNavigate()

  const getPage = (page: number) => {
    if (pathname === '/users') {
      getUsersKy(navigate, page, searchText)
      return
    } else {
      getEventsKy(navigate, page, searchText, typeFilter, dateFilter, myEventsFilter)
      return
    }
  }

  return (
    <Flex w='100%' justify='flex-end'>
      <Pagination onChange={getPage} total={total} color='black' />
    </Flex>
  )
}
