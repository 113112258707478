import { createStore } from 'effector'
import {
  removeNewDateFields,
  setCategoryEvent,
  setDateEnd,
  setDateStart,
  setNewDateFields,
} from './events'

export const $categoryEvent = createStore<any[]>([]).on(
  setCategoryEvent,
  (state, categoryEvent) => categoryEvent
)

interface DateForm {
  id: number
  dateStart: Date | null
  dateEnd: Date | null
}

interface DateItem {
  id: number
  date: Date | null
}

export const $dateStartEnd = createStore<DateForm[]>([
  {
    id: 1,
    dateStart: null,
    dateEnd: null,
  },
])
  .on(setDateStart, (state, date) => {
    return returnObjectDateStart(state, date)
  })
  .on(setDateEnd, (state, date) => {
    return returnObjectDateEnd(state, date)
  })
  .on(setNewDateFields, (state, obj) => {
    return [...state, obj]
  })
  .on(removeNewDateFields, (state, id) => {
    return state.filter((obj) => obj.id !== id)
  })

const returnObjectDateStart = (state: DateForm[], date: DateItem) => {
  const newArray = state.map((obj) => {
    if (obj.id === date.id) {
      return { ...obj, dateStart: date.date }
    }
    return obj
  })

  return newArray
}

const returnObjectDateEnd = (state: DateForm[], date: DateItem) => {
  const newArray = state.map((obj) => {
    if (obj.id === date.id) {
      return { ...obj, dateEnd: date.date }
    }
    return obj
  })

  return newArray
}
