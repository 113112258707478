import { createEvent } from 'effector'
export const newPhone = createEvent<string>()
export const newPhoneHash = createEvent<string>()
export const newEmail = createEvent<string>()
export const newEmailHash = createEvent<string>()
export const newRole = createEvent<boolean>()
export const newTOTP = createEvent<boolean>()
export const newApiKey = createEvent<string>()
export const setUserID = createEvent<string>()
export const setFirstName = createEvent<string>()
export const setLastName = createEvent<string>()
export const setPhoto = createEvent<any>()
export const setCurrentInviteToken = createEvent<string>()
export const isLoaderEvents = createEvent<void>()
export const isLoader = createEvent<void>()
export const setSearchText = createEvent<string>()
export const setTypeFilter = createEvent<number[]>()
export const setDateFilter = createEvent<Array<string | null>>()
export const setMyEventsFilter = createEvent<boolean>()
