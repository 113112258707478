import { createStore } from 'effector'
import { setAlertState, setMyInfo, setTotal } from './events'

export const $alertState = createStore<string | null>(null).on(
  setAlertState,
  (state, text) => text
)

export const $total = createStore<number>(1).on(setTotal, (state, total) => total)

export const $myInfo = createStore<boolean>(false).on(
  setMyInfo,
  (state, isInfo) => isInfo
)
