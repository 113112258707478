import { ActionIcon, CopyButton, Flex, Loader, Text, Title, Tooltip } from '@mantine/core'
import { useStore } from 'effector-react'
import { $currentInviteToken, $loaderEvents } from 'entities'
import { $currentRole } from 'entities/create-invite-token'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
// eslint-disable-next-line import/no-internal-modules
import { ArrowLeft, Copy } from 'shared/asset'
import { $stepInviteToken, prevStepInvite } from '../model'
import { getTokenKy } from '../utils'

export const InviteGeneration = () => {
  const stepInviteToken = useStore($stepInviteToken)
  const currentInviteToken = useStore($currentInviteToken)
  const loader = useStore($loaderEvents)
  const navigate = useNavigate()
  const currentRole = useStore($currentRole)

  useEffect(() => {
    if (stepInviteToken === 'InviteGenerationAdmin') {
      getTokenKy(navigate, currentRole.id)
      return
    }
  }, [stepInviteToken])

  return (
    <Flex mb={20} p='0px 60px' gap={40} direction='column' w='100%'>
      <ActionIcon
        onClick={() => {
          prevStepInvite('')
        }}
        color='white'
      >
        <ArrowLeft />
      </ActionIcon>
      <Flex direction='column' w='100%'>
        <Title>Генерация инвайт токена</Title>
        <Text>
          {`Данный токен позволяет назначить пользователю права ${currentRole.name}. Скопируйте код и вышлите...`}
        </Text>
      </Flex>
      {loader ? (
        <Flex w='100%' h={56} align='center'>
          <Loader />
        </Flex>
      ) : (
        <Flex
          p={15}
          w='100%'
          h={56}
          align='center'
          justify='space-between'
          style={{
            borderRadius: '12px',
            border: '1px solid #BEBEBE',
          }}
        >
          <Text>{currentInviteToken}</Text>
          <CopyButton value={currentInviteToken} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position='right'>
                <ActionIcon
                  color={copied ? 'teal' : 'gray'}
                  variant='subtle'
                  onClick={copy}
                >
                  {copied ? <Copy /> : <Copy />}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Flex>
      )}
    </Flex>
  )
}
