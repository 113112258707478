import { Button, Flex } from '@mantine/core'
// eslint-disable-next-line import/no-internal-modules
import { Link } from 'react-router-dom'
// eslint-disable-next-line import/no-internal-modules
import { Logo, Question } from 'shared/asset'
import { PropsLoginPage } from './types'

export const Sidebar = ({ bg, pl, pr }: PropsLoginPage) => {
  return (
    <Flex direction='column' p={`40px ${pr}px 0px ${pl}`} w={320} h='100%' bg={bg}>
      {/* logo */}
      <Flex mb={40} w='100%' pl={pl === 60 ? 0 : 30}>
        <Logo />
      </Flex>

      {/* нижняя панель */}
      <Flex gap={12} left={pl} pos='absolute' bottom={40} direction='column'>
        <Link to='https://reg.terra-leader.ru/help/' target='_blank'>
          <Button
            fz={16}
            fw={400}
            size='compact-md'
            bg={bg}
            c='#8F8F8F'
            leftSection={<Question />}
          >
            Служба поддержки
          </Button>
        </Link>
      </Flex>
    </Flex>
  )
}
