// eslint-disable-next-line import/no-internal-modules
import { Exit, Plus, QuestionMark } from 'shared/asset'
import { removeItemAndExit } from 'shared/lib/remove-item-and-exit'

const help = () => {
  window.open('https://t.me/terra_support_application')
}

const invite = () => {}

const exit = () => {
  removeItemAndExit()
}

export const dataButtonsSetting = [
  {
    id: 1,
    title: 'Помощь',
    img: <QuestionMark />,
    fn: help,
  },
  {
    id: 2,
    title: 'Пригласить',
    img: <Plus />,
    fn: invite,
  },
  {
    id: 3,
    title: 'Выйти',
    img: <Exit />,
    fn: exit,
  },
]
