import { ContentPage } from 'shared/ui'

const UsersPage = () => {
  return (
    <div>
      <ContentPage />
    </div>
  )
}

export default UsersPage
