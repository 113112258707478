/* eslint-disable functional/immutable-data */
/* eslint-disable prefer-const */
/* eslint-disable functional/no-let */
import {
  ActionIcon,
  Button,
  Flex,
  Loader,
  Modal,
  NumberInput,
  Select,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { useStore } from 'effector-react'
// eslint-disable-next-line import/no-internal-modules
import {
  $dateFilter,
  $loaderEvents,
  $myEventsFilter,
  $searchText,
  $typeFilter,
} from 'entities'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
// eslint-disable-next-line import/no-internal-modules
// eslint-disable-next-line import/no-internal-modules
// eslint-disable-next-line import/no-internal-modules
import { iscCreateEventsKy } from 'shared/api'
// eslint-disable-next-line import/no-internal-modules
import { PlusWhite, Trasher } from 'shared/asset'
import { CustomDateTimePicker } from '../custom-date-time-picker'
import {
  $categoryEvent,
  $dateStartEnd,
  removeNewDateFields,
  setDateEnd,
  setDateStart,
  setNewDateFields,
} from './model'
import { requestCategoryEvent } from './utils'

export const CreateEvent = () => {
  const loader = useStore($loaderEvents)
  const [opened, { open, close }] = useDisclosure(false)
  const navigate = useNavigate()
  const categoryEvent = useStore($categoryEvent)
  const dateStartEnd = useStore($dateStartEnd)
  const searchText = useStore($searchText)
  const typeFilter = useStore($typeFilter)
  const dateFilter = useStore($dateFilter)
  const myEventsFilter = useStore($myEventsFilter)

  useEffect(() => {
    requestCategoryEvent()
  }, [])

  const formatEventData = [
    {
      typeID: 1,
      typeName: 'Оффлайн',
      typeDescription: 'фывфыв',
    },
    {
      typeID: 2,
      typeName: 'Онлайн',
      typeDescription: 'фыв',
    },
    {
      typeID: 3,
      typeName: 'Оффлайн/Онлайн',
      typeDescription: 'фывфывфыв',
    },
  ]

  function getformatEvent(formatEvent: string) {
    const foundType = formatEventData.find((format) => format.typeName === formatEvent)
    return foundType ? foundType.typeID : null
  }

  function getCategoryEvent(typeEvent: string) {
    const foundType = categoryEvent.find(
      (category) => category.categoryName === typeEvent
    )
    return foundType ? foundType.categoryID : null
  }

  const formatEventName = formatEventData.map((item) => {
    return item.typeName
  })

  const categoryEventName = categoryEvent.map((item) => {
    return item.categoryName
  })

  const form = useForm({
    initialValues: {
      name: localStorage.getItem('nameCreateEvent')
        ? (localStorage.getItem('nameCreateEvent') as string)
        : '',
      typeEvent: localStorage.getItem('typeEventCreateEvent')
        ? (localStorage.getItem('typeEventCreateEvent') as string)
        : '',
      formatEvent: localStorage.getItem('formatEventCreateEvent')
        ? (localStorage.getItem('formatEventCreateEvent') as string)
        : '',
      numberUsers: localStorage.getItem('numberUsersCreateEvent')
        ? (localStorage.getItem('numberUsersCreateEvent') as string)
        : '',
      addressEvent: localStorage.getItem('addressEventCreateEvent')
        ? (localStorage.getItem('addressEventCreateEvent') as string)
        : '',
      date: '',
      howToGet: localStorage.getItem('howToGetCreateEvent')
        ? (localStorage.getItem('howToGetCreateEvent') as string)
        : '',
      eventDescription: localStorage.getItem('eventDescriptionCreateEvent')
        ? (localStorage.getItem('eventDescriptionCreateEvent') as string)
        : '',
    },
    validateInputOnBlur: true,
    validate: {
      name: (value) => (/^.+$/.test(value) ? false : 'поле не должно быть пустым'),
      typeEvent: (value) => (/^.+$/.test(value) ? false : 'поле не должно быть пустым'),
      formatEvent: (value) => (/^.+$/.test(value) ? false : 'поле не должно быть пустым'),
      numberUsers: (value) =>
        Number(value) > -1 ? false : 'введите корректное число пользователей',
      addressEvent: (value) =>
        /^.+$/.test(value) ? false : 'поле не должно быть пустым',
      howToGet: (value) => (/^.+$/.test(value) ? false : 'поле не должно быть пустым'),
      eventDescription: (value) =>
        /^.+$/.test(value) ? false : 'поле не должно быть пустым',
    },
  })

  localStorage.setItem('nameCreateEvent', form.values.name ? form.values.name : '')
  localStorage.setItem(
    'formatEventCreateEvent',
    form.values.formatEvent ? form.values.formatEvent : ''
  )
  localStorage.setItem(
    'typeEventCreateEvent',
    form.values.typeEvent ? form.values.typeEvent : ''
  )
  localStorage.setItem(
    'numberUsersCreateEvent',
    form.values.numberUsers ? form.values.numberUsers : ''
  )
  localStorage.setItem(
    'addressEventCreateEvent',
    form.values.addressEvent ? form.values.addressEvent : ''
  )
  localStorage.setItem(
    'howToGetCreateEvent',
    form.values.howToGet ? form.values.howToGet : ''
  )
  localStorage.setItem(
    'eventDescriptionCreateEvent',
    form.values.eventDescription ? form.values.eventDescription : ''
  )

  const isDateValid = () => {
    if (dateStartEnd[0].dateStart && dateStartEnd[0].dateEnd) {
      return true
    } else {
      return false
    }
  }

  const handleSubmit = (values: any) => {
    const newDates = dateStartEnd.map((date) => {
      return {
        id: date.id,
        dateStart: formatDate(String(date.dateStart)),
        dateEnd: formatDate(String(date.dateEnd)),
      }
    })
    const event = {
      ...values,
      numberUsers: values.numberUsers === '' ? null : values.numberUsers,
      formatEvent: getformatEvent(values.formatEvent),
      typeEvent: getCategoryEvent(values.typeEvent),
      date: newDates,
    }

    iscCreateEventsKy(
      navigate,
      event,
      close,
      1,
      searchText,
      typeFilter,
      dateFilter,
      myEventsFilter
    )
  }

  return (
    <>
      <ActionIcon onClick={open} variant='filled' aria-label='Plus'>
        <PlusWhite />
      </ActionIcon>

      <Modal
        title={<Title>Новое событие</Title>}
        centered
        radius={24}
        size={718}
        opened={opened}
        onClose={close}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            handleSubmit(values)
          })}
        >
          <Flex
            mb={20}
            p='0px 60px'
            gap={16}
            direction='column'
            w='100%'
            align='flex-end'
          >
            <Button
              onClick={() => {
                form.values.name = ''
                form.values.typeEvent = categoryEventName[0]
                form.values.formatEvent = formatEventName[0]
                form.values.numberUsers = ''
                form.values.addressEvent = ''
                form.values.howToGet = ''
                form.values.eventDescription = ''
                form.reset()
              }}
              radius={8}
              w='50%'
            >
              очистить форму
            </Button>
            <TextInput
              withAsterisk
              key={form.key('name')}
              {...form.getInputProps('name')}
              placeholder='Наименовние'
              radius={8}
              w='100%'
            />
            <Flex w='100%' justify='space-between'>
              <Select
                withAsterisk
                key={form.key('typeEvent')}
                {...form.getInputProps('typeEvent')}
                w={280}
                radius={8}
                placeholder='Тип события'
                data={categoryEventName}
              />
              <Select
                withAsterisk
                key={form.key('formatEvent')}
                {...form.getInputProps('formatEvent')}
                w={280}
                radius={8}
                placeholder='Формат'
                data={formatEventName}
              />
            </Flex>
            <NumberInput
              key={form.key('numberUsers')}
              {...form.getInputProps('numberUsers')}
              placeholder='Количество пользователей'
              radius={8}
              w='100%'
            />
            <TextInput
              withAsterisk
              key={form.key('addressEvent')}
              {...form.getInputProps('addressEvent')}
              placeholder='Адрес проведения'
              radius={8}
              w='100%'
            />
            {dateStartEnd.map((itemDate) => {
              return <CreateEventDate key={itemDate.id} id={itemDate.id} />
            })}

            <Textarea
              withAsterisk
              key={form.key('howToGet')}
              {...form.getInputProps('howToGet')}
              w='100%'
              radius={8}
              placeholder='Как добратся'
            />
            <Textarea
              withAsterisk
              key={form.key('eventDescription')}
              {...form.getInputProps('eventDescription')}
              w='100%'
              radius={8}
              placeholder='Описание события'
            />

            <Button
              disabled={!form.isValid() || !isDateValid()}
              type='submit'
              color='black'
              radius={8}
              w='100%'
            >
              {loader ? <Loader color='white' /> : 'Создать'}
            </Button>
          </Flex>
        </form>
      </Modal>
    </>
  )
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString)

  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const seconds = date.getSeconds().toString().padStart(2, '0')

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
}

const CreateEventDate = ({ id }: any) => {
  const dateStartEnd = useStore($dateStartEnd)
  const currentDateStartEnd = dateStartEnd.find((obj) => obj.id === id)

  return (
    <Flex align='center' w='100%' direction='column'>
      <Flex w='100%' align='flex-end' justify='space-between'>
        <CustomDateTimePicker
          label='Начало события'
          w={250}
          onChange={(e) => {
            setDateStart({
              id: id,
              date: e,
            })
          }}
        />
        <CustomDateTimePicker
          label='Конец события'
          w={250}
          onChange={(e) => {
            setDateEnd({
              id: id,
              date: e,
            })
          }}
        />
        <ActionIcon
          h={37}
          w={37}
          onClick={() => {
            actionIconDateClick(dateStartEnd, id)
          }}
          variant='filled'
          color='rgba(158, 155, 155, 1)'
          aria-label='Plus'
        >
          {id === 1 ? <PlusWhite /> : <Trasher />}
        </ActionIcon>
      </Flex>
      <Text
        fz={12}
        style={{
          display: isDateValidText(
            currentDateStartEnd ? currentDateStartEnd.dateStart : new Date(),
            currentDateStartEnd ? currentDateStartEnd.dateEnd : new Date()
          )
            ? 'none'
            : 'block',
        }}
        color='red'
      >
        конец события не может быть меньше начала
      </Text>
    </Flex>
  )
}

const isDateValidText = (dateStart: Date | null, dateEnd: Date | null) => {
  if (dateStart && dateEnd) {
    if (dateStart <= dateEnd) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

const actionIconDateClick = (dateStartEnd: any, id: number) => {
  // eslint-disable-next-line functional/no-conditional-statements
  if (id === 1) {
    setNewDateFields({
      id: dateStartEnd.length + 1,
      dateStart: null,
      dateEnd: null,
    })
    // eslint-disable-next-line functional/no-conditional-statements
  } else {
    removeNewDateFields(id)
  }
}
