import { createStore } from 'effector'
import { nextStep, prevStep, setPinError, setQr } from './events'

export const $stepForm = createStore<string[]>(['FormPhoneAndEmail'])
  .on(prevStep, (state) => ['FormPhoneAndEmail'])
  .on(nextStep, (state, form) => {
    if (state[state.length - 1] !== form) {
      return [...state, form]
    }
  })

export const $qr = createStore<string>('').on(setQr, (state, qr) => qr)

export const $pinError = createStore<boolean>(false).on(
  setPinError,
  (state, value) => value
)
