import { Flex, Text } from '@mantine/core'
import { useLocation, useNavigate } from 'react-router'
// eslint-disable-next-line import/no-internal-modules
import { Logo } from 'shared/asset'
import { ButtonSwitchingPageProps } from 'shared/lib/shared-types'
import { dataSwitchingPages } from './utils'

const SwitchingPages = () => {
  const location = useLocation()

  return (
    <Flex direction='column' align='center' gap={40}>
      <Logo />
      <Flex w='100%' direction='column' gap={16}>
        {dataSwitchingPages.map((item) => {
          return (
            <ButtonSwitchingPage
              key={item.id}
              title={item.title}
              active={item.link === location.pathname}
              img={item.img}
              link={item.link}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default SwitchingPages

const ButtonSwitchingPage = ({ title, active, img, link }: ButtonSwitchingPageProps) => {
  const navigate = useNavigate()
  return (
    <Flex
      onClick={() => {
        navigate(link)
      }}
      w='100%'
      h={56}
      align='center'
      gap={8}
      bg={active ? '#1681FF0D' : '#F9F9F9'}
      p={16}
      style={{
        borderRadius: '12px',
        cursor: 'pointer',
      }}
    >
      {img}
      <Text c={active ? '#1681FF' : 'black'} fz={16} fw={400}>
        {title}
      </Text>
    </Flex>
  )
}
