export const returnStatus = (id: number) => {
  if (id === 2) {
    return {
      text: 'пришел',
      color: '#319772',
      background: '#F1FFFA',
    }
  } else if (id === 3) {
    return {
      text: 'не пришел',
      color: '#FF9500',
      background: '#FFF6EA',
    }
  } else if (id === 4) {
    return {
      text: 'удалил регистрацию',
      color: '#F24B3B',
      background: '#FFECEA',
    }
  } else if (id === 5) {
    return {
      text: 'заблокирован',
      color: '#F24B3B',
      background: '#FFECEA',
    }
  } else {
    return {
      text: 'зарегистрирован',
      color: '#1681FF',
      background: '#E2F0FF',
    }
  }
}
