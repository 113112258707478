import { format, parseISO } from 'date-fns'
import React, { useState } from 'react'
import './custom-date-time-picker.css'

interface DateTimePickerProps {
  label?: string
  initialValue?: Date
  onChange?: (value: Date | null) => void
  w?: number
}

export const CustomDateTimePicker: React.FC<DateTimePickerProps> = ({
  w,
  label,
  initialValue,
  onChange,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(initialValue || null)

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value ? parseISO(event.target.value) : null
    setSelectedDate(newDate)
    // eslint-disable-next-line functional/no-conditional-statements
    if (onChange) {
      onChange(newDate)
    }
  }

  return (
    <div className='date-time-picker'>
      {label && <label className='date-time-picker-label'>{label}</label>}
      <div
        style={{
          width: `${w}px`,
        }}
        className='date-time-picker-container'
      >
        <input
          type='datetime-local'
          className='date-time-picker-input'
          value={selectedDate ? format(selectedDate, "yyyy-MM-dd'T'HH:mm") : ''}
          onChange={handleDateChange}
        />
      </div>
    </div>
  )
}
