import { Flex, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { ButtonSettingProps } from 'shared/lib/shared-types'
// eslint-disable-next-line boundaries/element-types
import CreateInviteToken from 'widgets/create-invite-token'
import { dataButtonsSetting } from './utils'

const ButtonsSettings = () => {
  return (
    <Flex w='100%' direction='column' gap={16}>
      {dataButtonsSetting.map((item) => {
        return (
          <ButtonSetting key={item.id} title={item.title} fn={item.fn} img={item.img} />
        )
      })}
    </Flex>
  )
}

export default ButtonsSettings

const ButtonSetting = ({ fn, img, title }: ButtonSettingProps) => {
  const [opened, { open, close }] = useDisclosure(false)
  return (
    <>
      <Flex
        onClick={() => {
          if (title === 'Пригласить') {
            open()
            return
          } else {
            fn()
            return
          }
        }}
        w='100%'
        h={56}
        align='center'
        gap={8}
        bg='#F9F9F9'
        p={16}
        style={{
          borderRadius: '12px',
          cursor: 'pointer',
        }}
      >
        {img}
        <Text c='black' fz={16} fw={400}>
          {title}
        </Text>
      </Flex>

      <CreateInviteToken opened={opened} close={close} />
    </>
  )
}
