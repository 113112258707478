import { createStore } from 'effector'
import { RegitredUser } from '../types'
import { mockRegistredUser, returnCorrectREgistredUser } from '../utils'
import { isEdit, setSearchTurn, setStateDrawerTable, setUsersRegistered } from './events'

export const $searchTurn = createStore<string>('').on(setSearchTurn, (state, str) => str)

export const $stateParticipantsAndVolunteers = createStore<string>('participants').on(
  setStateDrawerTable,
  (state, text) => text
)

export const $edit = createStore<boolean>(false).on(isEdit, (state, value) => value)

export const $usersRegistered = createStore<RegitredUser[]>([mockRegistredUser]).on(
  setUsersRegistered,
  (state, users) => {
    return users.map((user: RegitredUser) => {
      return returnCorrectREgistredUser(mockRegistredUser, user)
    })
  }
)
